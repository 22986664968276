let projectServiceBaseURL;
//uncomment 4 lines after props.LoginFunction and   props.verifyOtp( in LoginComponent.js once you provide correct to for login
//remove 13 lines from //--start-- in logincomponent.js

// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "https://ssipl.herokuapp.com/api/";
// } else {
//   projectServiceBaseURL = "https://ssipl.herokuapp.com/api/";
// }


//  Development
// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "https://ssipldev.grainbyte.app/bri/api/";
// } else {
//   projectServiceBaseURL = "https://ssipldev.grainbyte.app/bri/api/";
// }
if (process.env.REACT_APP_ENV === "production") {
  projectServiceBaseURL = "https://bri.grainbyte.app/api/";
} else {
  projectServiceBaseURL = "https://bri.grainbyte.app/api/";
}



 
// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "http://103.71.21.114:5000/api/";
// } else {
//   projectServiceBaseURL = "http://103.71.21.114:5000/api/";
// }

export const loginURL = projectServiceBaseURL + "auth/";

export const verifyOtpURL = projectServiceBaseURL + "auth/user-auth/verify-otp/";

export const moduleURL = (module,noSlash) => {
  return projectServiceBaseURL + module +(noSlash?"": "/");
};
export const moduleIdURL = (module, id,noSlash) => {
  return projectServiceBaseURL + module + "/" + id + (noSlash?"":"/");
};
export const noFmoduleIdURL = (module) => {
  return projectServiceBaseURL + module;
};
