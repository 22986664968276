import React, { Component } from "react";
import { CssButton, CssCreateTextField } from "./PurchaseOrdersComponent";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Stepper, Typography, StepContent } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import CustomStepperPO from "../../layoutComponents/CustomStepperPO";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableComponent from "../../layoutComponents/TableComponent";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Tooltip,
  withStyles,
  Button,
} from "@material-ui/core";
import { lightGrey, primary, red, white } from "../../styles/colors";
import MultipleSelectBar from "../../layoutComponents/MultipleSelectBar";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import ReactDOM from "react-dom";
import PurchaseIcon from "@mui/icons-material/AddShoppingCart";
import {
  BottomArrow,
  CrossIcon,
  EditIcon,
  Reload,
  TopArrow,
} from "../../styles/Icons";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { Add, CheckCircle } from "@material-ui/icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filterData, StopScroll, getSingleModule_widget } from "../../Regex";
import { EndPoints } from "../../utils/EndPoints";
import PurchaseOrderPdf from "./PurchaseOrderPdf";
import ShowSpecifications, {
  getSpecificationId,
} from "../../layoutComponents/ShowSpecifications";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import { SalesOrdersFunction } from "../../redux";
import ShowPackaging from "../../layoutComponents/ShowPackagingPort";
import { accessText } from "../../routes/sidebar";
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
  },
  none: { height: 0, width: 0 },
  innerDiv: {
    height: "50%",
    background: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    pointerEvents: "none",
    width: "100%",
  },
  innerDiv1: {
    height: "65%",
    // background: "rgba(251, 224, 191, 1)",
    borderRadius: "10px",
    // padding: "10px 10px 0 10px",
    width: "100%",
    pointerEvents: "none",
  },
  innerMainDiv: {
    width: "100%",
    display: "flex",
  },
  heading1: {
    fontSize: "1rem",
    color: "rgba(150, 150, 150, 1)",
  },
  subHeading: {
    fontSize: "0.8rem",
    color: "black",
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CssValueTextField = withStyles({
  root: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      color: "black",
    },
    "& label.Mui-focused": {
      color: "rgb(241 154 48)",
    },
    "& .MuiOutlinedInput-root": {
      caretColor: "transparent",
      borderColor: primary,
      "& fieldset": {
        borderColor: "#0000ff69",
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(TextField);
export const CssNewTextField = withStyles({
  root: {
    "& .MuiFormHelperText-contained": {
      marginLeft:"5px",
    },
    "& .MuiOutlinedInput-input":{
      padding:"15.5px 14px"
    },

   "& .MuiInputBase-root": {
      background:"rgb(0 0 0 / 1%)",
  }    
    
  },
})(TextField);
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
export class Details extends Component {
  constructor(props) {
    const surveyStatus = [
      { name: "Moisture" },
      { name: "Broken" },
      { name: "Damaged/Coloured" },
      { name: "Chalky" },
      { name: "Length" },
      { name: "Paddy" },
      { name: "Foreign Matter" },
      { name: "Double Polish" },
    ];
    const MaxMin = [
      { name: "Greater Than", id: "greater_than" },
      { name: "Greater Than Or Equal To", id: "greater_than_or_equal_to" },
      { name: "Less Than", id: "less_than" },
      { name: "Not Equal To", id: "not_equal_to" },
      { name: "Equal To", id: "equal_to" },
      { name: "Less Than Or Equal To", id: "less_than_or_equal_to" },
    ];
    super(props);
    this.state = {
      openDialog: false,
      editDialog: false,
      loadingFun: false,
      error: false,
      success: false,
      added: false,
      calenderOpenLS: false,
      selctedPOStatus: "OPEN",
      SurveyStatus: surveyStatus,
      MaxMin: MaxMin,
      specificationArr: [],
      specificationArrCopy: [],
      specificationId: false,
      submittedData: {},
      submittedItemData: {},
      masters: {},
      errorMsg: {
        Unit: false,
        contractTypeName: false,
        CashDiscount: false,
        BorkerName: false,
        Borkerage: false,
        portArry: false,
        MillerName: false,
        BrandName: false,
        HSN: false,
        Rate: false,
        PaymentTerm: false,
        Remark: false,
        poNum: false,

        packagingArr: false,
      },
      Unit: "",
      contractTypeName: "",
      contractTypeId: "",
      CashDiscount: "",
      CashDiscountId: "",
      BorkerName: "",
      BorkerNameId: "",
      Borkerage: "",
      BorkerageId: "",
      portArry: [],
      MillerNameId: "",
      MillerName: "",
      MillerAddress: "",
      MillerGST: "",

      ContactPerson: "",
      last_survey: new Date(),
      calenderOpen: false,
      SalesOrderId: "",
      editSnack: false,
      SalesOrder: "",
      BrandName: [],
      selectedBrandMultipleName: false,
      ItemCategory: "",
      ItemType: "",
      packagingArr: [],
      HSN: "",
      HSN_id: "",
      total_quantity: "",
      Rate: "",
      searchedData: new Date(),
      selectedPoid: false,
      PaymentTerm: "",
      PaymentTermId: "",
      onlyDialog: false,
      Remark: "",
      poNum: "",
      selectedSOItems: [],

      onlyUi: false,
      downloadPdf: false,
      pushNoti: false,
      showSpeci: false,
      poItemsArray: [],
      poItemsArrayCopy: [],
      defaultStep: 0,
      currentStep: 0,
      moveToShipHeader2: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Buyer Marking",
        "Specifications",
      ],
      specificationHeader: [
        {
          name: "Type",
          type: "select",
          dataType: "text",
          api: "name",
          array: surveyStatus,
          value: "name",
          filter: (array, specificationArr) => {
            return (
              array &&
              array.length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ) &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ).length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              )
            );
          },
        },
        {
          name: "Condition",
          type: "select",
          dataType: "text",
          api: "condition",
          array: MaxMin,
          value: "name",
        },
        {
          name: "Value",
          type: "textField",
          dataType: "number",
          api: "value",
        },
      ],
      submittedSpecifies: {},
      confirmAddWithoutSpecification: false,
      loader: false,
    };
  }
  getSpecificationName = (sp) => {
    switch (sp) {
      case "equal_to":
        return "only";

      case "not_equal_to":
        return "not";

      case "greater_than":
        return "above";

      case "greater_than_or_equal_to":
        return "min";

      case "less_than":
        return "below";

      case "less_than_or_equal_to":
        return "max";
    }
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  reset = (noSnack) => {
    const {
      editDialog,
      contractTypeName,
      PaymentTerm,
      MillerName,
      ContactPerson,
      CashDiscount,
      BorkerName,
      BrandName,
      Remark,
      poNum,
      portArry,
      packagingArr,
      Rate,
      Unit,
      HSN,
      SalesOrder,
      total_quantity,
      selectedSOItems,
    } = this.state;
    const { singlelistView } = this.props;
    if (editDialog !== false) {
      this.setState(
        {
          editDialog: singlelistView,
          loadingFun: true,
          showSpeci: false,
        },
        () => {
          this.setData(
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          );
        }
      );
    } else {
      if (
        !Rate &&
        !Unit &&
        !HSN &&
        !Remark &&
        !poNum &&
        BrandName.length===0 &&
        !CashDiscount &&
        !BorkerName &&
        !ContactPerson &&
        !MillerName &&
        !PaymentTerm &&
        !contractTypeName &&
        packagingArr.length === 0 &&
        portArry.length === 0 &&
        !SalesOrder &&
        !total_quantity &&
        selectedSOItems.length === 0
      ) {
        this.setState({
          error: noSnack ? false : "Nothing to reset !",
          submittedData: {},
          submittedItemData: {},
          errorMsg: {
            Unit: false,
            contractTypeName: false,
            CashDiscount: false,
            BorkerName: false,
            Borkerage: false,
            portArry: false,
            MillerName: false,
            BrandName: false,
            HSN: false,
            Rate: false,
            PaymentTerm: false,
            Remark: false,
            poNum: false,
            packagingArr: false,
          },
        });
      } else {
        this.props.clearPoItems();
        this.clearSurveyFields();
        this.setState(
          {
            specificationArrCopy: [],
            specificationArr: [],
            total_quantity: "",
            BrandNameId: "",
            BrandName: [],
            selectedBrandMultipleName: false,
            selctedPOStatus: "OPEN",
            SalesOrder: "",
            SalesOrderId: "",
            contractTypeName: "",
            PaymentTerm: "",
            MillerName: "",
            MillerAddress: "",
            MillerGST: "",
            ContactPerson: "",
            ItemCategory: "",
            itemsId: "",
            ItemCategoryId: "",
            ItemType: "",
            CashDiscount: "",
            BorkerName: "",
            Borkerage: "",
            Remark: "",
            poNum: "",
            portArry: [],
            packagingArr: [],
            Rate: "",
            Unit: "",
            HSN: "",
            HSN_id: "",
            selectedSOItems: [],
            submittedData: {},
            submittedItemData: {},
            errorMsg: {
              Unit: false,
              contractTypeName: false,
              CashDiscount: false,
              BorkerName: false,
              Borkerage: false,
              portArry: false,
              MillerName: false,
              BrandName: false,
              HSN: false,
              Rate: false,
              PaymentTerm: false,
              Remark: false,
              poNum: false,
              packagingArr: false,
            },
          },
          () => {
            this.setState({
              success: noSnack ? false : "Reset successfully !",
            });
          }
        );
      }
    }
  };
  setData = (Unit) => {
    const {
      MillerList,
      ItemCateoryList,
      ItemList,
      BrokerList,
      ContractTypeList,
      PackagingList,
      PortsList,
      PaymentTermList,
      PO_Items_List,
      poItemheader,
      HSNlist,
      CashDiscountList,
      singlelistView,
      poPackahging, BrandList,
    } = this.props;
    
    const { editDialog } = this.state;

    const oldnewPortArr = editDialog.ports ? editDialog.ports : [];
    const newPortArr = [];
    if (PortsList && PortsList.length > 0) {
      PortsList.map((sPack) => {
        if (oldnewPortArr.includes(sPack.id.toString())) {
          return newPortArr.push(sPack);
        } else {
          return null;
        }
      });
    }

    const newPOItemArray = [];
    if (PO_Items_List && PO_Items_List.length > 0) {
      PO_Items_List &&
        PO_Items_List.map((sList) => {
        
          const packaging_details = [];
          const pId = filterData(poPackahging, "po_items", sList.id)
            ? filterData(poPackahging, "po_items", sList.id).map((s) => {
                return s.packaging.toString();
              })
            : [];
            
          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                poPackahging &&
                filterData(poPackahging, "po_items", sList.id) &&
                filterData(
                  poPackahging &&
                    filterData(poPackahging, "po_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      poPackahging &&
                        filterData(poPackahging, "po_items", sList.id),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;

              if (pId.includes(sPack.id.toString()) || pId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }
        const allBrands=BrandList.filter((s)=>sList.brand_details.variants.includes(s.id))??[]
          const addData = {
            ...sList,
            soitem_id: sList.so_item_detail ? sList.so_item_detail : undefined,
            // sList.so_item_details && sList.so_item_details.length > 0
            //   ? sList.so_item_details[0].id
            //   : undefined,
            brand: sList.brand_details.brand_name ?? sList.brand,

            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            // BrandName: packaging_details.map((sP) => {
            //   sP.sizeUnit = `${sP.size} ${sP.unit}`;
            //   return sP;
            // }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;
          addData[poItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name
              : "";
          addData[`${poItemheader[1]}id`] = sList.item_category;

          addData[poItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0].broken_percentage
              : "";
          addData[`${poItemheader[2]}id`] = sList.item_type;
          console.log(sList, "sList");
          addData[poItemheader[0]] = allBrands.map((s)=>s.brand_name).join(", ")
          addData[`${poItemheader[0]}id`] = allBrands

          addData.selectedBrandMultipleName = `${
            sList.brand_details.brand_name
          }-${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  .broken_percentage
              : ""
          } %`;
          addData[poItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${poItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details.map((sP) => {
            const newObj = {};
            newObj.id = sP.id;
            newObj.bag_type = sP.bag_type;
            newObj.size = sP.size;
            newObj.unit = sP.unit;
            newObj.bag_weight = sP.bag_weight ?? "";
            newObj.sizeUnit = `${sP.size} ${sP.unit}`;
            newObj.used_bags_po = sP.used_bags ? sP.used_bags : 0;
            newObj.used_bags = 0;
            newObj.remaining_bags = sP.remaining_bags;
            return newObj;
          });
          addData[poItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";

          addData[`${poItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[poItemheader[5]] = sList.total_quantity;
          addData[`${poItemheader[5]}Unit`] = `${sList.total_quantity} ${
            Unit && Unit ? Unit : ""
          }`;

          addData[poItemheader[6]] = sList.contract_rate;

          addData["specificationArr"] = sList["specifications"];
          addData["Specifications"] = sList["specifications"];

          addData[`${poItemheader[6]}Unit`] = `${sList.contract_rate}/${
            Unit && Unit ? Unit : ""
          }`;

          addData.id = sList.id;
          addData.po_item_id = sList.id;
          newPOItemArray.push(addData);
          return sList;
        });
    }

    if (editDialog !== false) {
      if (
        editDialog.sales_order_details && editDialog.sales_order_details.id
          ? editDialog.sales_order_details.id
          : ""
      ) {
        this.props
          .PurchaseOrdersFunction(
            "get",
            "dispatch/so-items/list",
            null,
            null,
            null,
            {
              so_id: editDialog.sales_order_details.id,
            }
          )
          .then((soitems) => {
            if (soitems && soitems.length > 0) {
              const soDetail = {};
              soitems.map((s) => {
                const poofThisSo = filterData(
                  newPOItemArray,
                  "soitem_id",
                  s.id,
                  "onlyOne"
                )
                  ? filterData(newPOItemArray, "soitem_id", s.id, "onlyOne")
                  : {};
                soDetail[s.id] =
                  poofThisSo.total_quantity ?? s.total_quantity ?? 0;
                return s;
              });

              this.setState({
                soitemsList: soitems,
                submittedItemData: soDetail,
                selectedSOItems: newPOItemArray.map((po) => {
                  return po.soitem_id;
                }),
              });
            }
          });
      }
      this.props.setPoItems(newPOItemArray, newPOItemArray);

      this.setState(
        {
          selctedPOStatus: editDialog.status ? editDialog.status : "OPEN",

          SalesOrder:
            editDialog.sales_order_details &&
            editDialog.sales_order_details.so_number
              ? editDialog.sales_order_details.so_number
              : "",
          SalesOrderId:
            editDialog.sales_order_details && editDialog.sales_order_details.id
              ? editDialog.sales_order_details.id
              : "",

          last_survey: editDialog.last_survey ? editDialog.last_survey : "",
          searchedData: editDialog.contract_date,
          contractTypeName:
            ContractTypeList &&
            ContractTypeList.length > 0 &&
            ContractTypeList.filter((sM) =>
              sM.id === editDialog.contract_type_details.id
                ? editDialog.contract_type_details.id
                : 0
            )[0]
              ? ContractTypeList.filter((sM) =>
                  sM.id === editDialog.contract_type_details.id
                    ? editDialog.contract_type_details.id
                    : 0
                )[0].contract_type
              : "",
          contractTypeId: editDialog.contract_type_details.id
            ? editDialog.contract_type_details.id
            : 0,
          PaymentTermId: editDialog.payment_terms
            ? Number(editDialog.payment_terms)
            : 0,
          PaymentTerm:
            PaymentTermList &&
            PaymentTermList.length > 0 &&
            PaymentTermList.filter(
              (sM) => sM.id === Number(editDialog.payment_terms)
            )[0]
              ? PaymentTermList &&
                PaymentTermList.length > 0 &&
                PaymentTermList.filter(
                  (sM) => sM.id === Number(editDialog.payment_terms)
                )[0].days_range
              : "",

          MillerNameId: editDialog.miller_details.id
            ? editDialog.miller_details.id
            : 0,
          MillerName:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].name
              : "",

          MillerAddress:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].address
              : "",
          MillerGSTId: "",
          MillerGST:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].gstin
              : "",

          ContactPerson:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].contact_person
              : "",
          ContactPersonId: editDialog.miller_details.id
            ? editDialog.miller_details.id
            : 0,

          Borkerage:
            BrokerList &&
            BrokerList.length > 0 &&
            BrokerList.filter((sM) =>
              sM.id === editDialog.broker_details.id
                ? editDialog.broker_details.id
                : 0
            )[0]
              ? BrokerList.filter((sM) =>
                  sM.id === editDialog.broker_details.id
                    ? editDialog.broker_details.id
                    : 0
                )[0].default_brokerage
              : "",
          BorkerageId: editDialog.broker_details.id
            ? editDialog.broker_details.id
            : 0,
          BorkerName:
            BrokerList &&
            BrokerList.length > 0 &&
            BrokerList.filter((sM) =>
              sM.id === editDialog.broker_details.id
                ? editDialog.broker_details.id
                : 0
            )[0]
              ? BrokerList.filter((sM) =>
                  sM.id === editDialog.broker_details.id
                    ? editDialog.broker_details.id
                    : 0
                )[0].name
              : "",
          BorkerNameId: editDialog.broker_details.id
            ? editDialog.broker_details.id
            : 0,

          CashDiscountId: editDialog.cash_discount
            ? editDialog.cash_discount
            : 0,
          CashDiscount:
            CashDiscountList &&
            CashDiscountList.length > 0 &&
            CashDiscountList.filter((sM) =>
              sM.id === editDialog.cash_discount ? editDialog.cash_discount : 0
            )[0]
              ? CashDiscountList.filter((sM) =>
                  sM.id === editDialog.cash_discount
                    ? editDialog.cash_discount
                    : 0
                )[0].discount
              : "",

          Remark: editDialog.remark ? editDialog.remark : "",
          poNum: editDialog.po_number ? editDialog.po_number : "",
          portArry: newPortArr,

          Unit: editDialog.unit,
        },

        () => {
          this.setState({ loadingFun: false });
        }
      );
    }
  };
  handleClearFields = () => {
    this.setState({
      added: false,
      loadingFun: false,
      success: false,
      error: false,
    });
  };
  clearSurveyFields = () => {
    const errorMsg = this.state.errorMsg;
    errorMsg.percentageValue = false;
    errorMsg.selectedSurrvayStatus = false;
    errorMsg.selectedCondition = false;
    this.setState({
      errorMsg,
      percentageValue: "",
      selectedSurrvayStatus: "",
      selectedCondition: "",
      selectedConditionId: "",
      specificationId: false,
    });
  };
  setInitialValues = (next) => {
    const {
      openDialog,
      onlyDialog,
      onlyUi,
      poItemsArray,
      poItemsArrayCopy,
      loader,
      mastersList,
    } = next;
    if (openDialog === true && this.state.openDialog === false) {
      this.setState(
        {
          specificationArrCopy: [],
          specificationArr: [],
        },
        () => {
          this.clearSurveyFields();
        }
      );
    }
    this.setState({
      openDialog,
      onlyDialog,
      onlyUi,
      poItemsArray,
      poItemsArrayCopy,
      loader,
      masters: mastersList,
    });
    if (openDialog !== this.state.openDialog) {
      this.setState({
        defaultStep: 0,
        currentStep: 0,
        submittedSpecifies: {},
      });
    }
  };
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.onlyCreate) {
      const item_categoy =
        typeof next.onlyCreate.item_category === "number"
          ? next.onlyCreate.item_category
          : filterData(
              next.masters["ItemCateoryList"],
              "name",
              next.onlyCreate.item_category,
              "onlyOne"
            )
          ? filterData(
              next.masters["ItemCateoryList"],
              "name",
              next.onlyCreate.item_category,
              "onlyOne"
            ).id
          : "";
      const item_categoyName = filterData(
        next.masters["ItemCateoryList"],
        "id",
        item_categoy,
        "onlyOne"
      )
        ? filterData(
            next.masters["ItemCateoryList"],
            "id",
            item_categoy,
            "onlyOne"
          ).name
        : "";
      const item_type =
        typeof next.onlyCreate.item_type === "number"
          ? next.onlyCreate.item_type
          : filterData(
              next.masters["ItemList"],
              "broken_percentage",
              next.onlyCreate.item_type,
              "onlyOne"
            )
          ? filterData(
              next.masters["ItemList"],
              "broken_percentage",
              next.onlyCreate.item_type,
              "onlyOne"
            ).id
          : "";
      const item_typeName = filterData(
        next.masters["ItemList"],
        "id",
        item_type,
        "onlyOne"
      )
        ? filterData(next.masters["ItemList"], "id", item_type, "onlyOne")
            .broken_percentage
        : "";
      //   const contractType =next.onlyCreate?next.onlyCreate.contract_type:"";
      // const contractTypeName =next.onlyCreate["Contract Type"]
      console.log(next.onlyCreate,next.ContractTypeList.find((s)=>s.contract_type===(next.onlyCreate.contract_type??next.onlyCreate.contract_type)),"next.onlyCreate");
      this.setState({
        ItemCategoryId: item_categoy,
        ItemCategory: item_categoyName,
        itemsId: item_type,
        ItemType: `${item_typeName.toString()} %`,
        total_quantity: next.onlyCreate.quantity,
        Rate: next.onlyCreate.amount,
        Unit: next.onlyCreate.unit,
        // contractTypeName:next.onlyCreate.contract_type,
        contractTypeName:next.onlyCreate?next.ContractTypeList.find((s)=>(s.contract_type===(next.onlyCreate.contract_type))||(s.id===(next.onlyCreate.contract_type)))?.contract_type:"",
        contractTypeId:next.onlyCreate?next.ContractTypeList.find((s)=>(s.contract_type===(next.onlyCreate.contract_type))||(s.id===(next.onlyCreate.contract_type)))?.id:"",
      });
      if (next.onlyCreate && next.onlyCreate.miller_profile) {
        this.setState({
          MillerNameId: next.onlyCreate.miller_profile?.id ?? "",
          MillerName: next.onlyCreate.miller_profile?.name ?? "",
          MillerAddress: next.onlyCreate.miller_profile?.address ?? "",
          MillerGST: next.onlyCreate.miller_profile?.gstin ?? "",
          ContactPerson: next.onlyCreate.miller_profile?.contact_person ?? "",
        });
      }
      if (next.onlyCreate && next.onlyCreate.broker_profile) {
        this.setState({
          Borkerage: next.onlyCreate.broker_profile?.default_brokerage ?? "",

          BorkerNameId: next.onlyCreate.broker_profile?.id ?? "",

          BorkerageId: next.onlyCreate.broker_profile?.id ?? "",
          BorkerName: next.onlyCreate.broker_profile?.name ?? "",
        });
      }
    }
    this.setInitialValues(next);
  }

  render() {
    const {
      singlelistView,
      poItemsArrayTOPreview,
      poItemsArrayTOPreviewCopy,
      POITemTable,
      PaymentTermList,
      status,
      unit,
      ContractTypeList,
      PortsList,
      MillerList,
      SalesOrderList,
      ItemCateoryList,
      ItemList,
      PackagingList,
      HSNlist,
      poItemheader,
      selctedStatus,
      selectedMonth,
      noTotalQuantity,
      poItemsArray,
      poItemsArrayCopy,
      CashDiscountList,
      showTotal,
      onlyCreate,BrandList
    } = this.props;

    const {
      specificationHeader,
      openDialog,
      editDialog,
      loadingFun,
      success,
      error,
      added,
      calenderOpenLS,
      errorMsg,
      selctedPOStatus,
      Unit,
      contractTypeName,
      contractTypeId,
      CashDiscount,
      CashDiscountId,
      BorkerName,
      BorkerNameId,
      BorkerageId,
      portArry,
      MillerNameId,
      MillerName,
      MillerAddress,
      MillerGST,
      ContactPerson,
      last_survey,
      SalesOrder,
      SalesOrderId,
      BrandName,
      selectedBrandMultipleName,
      ItemCategory,
      ItemType,
      packagingArr,
      total_quantity,
      HSN,
      HSN_id,
      Rate,
      BrandNameId,
      itemsId,
      ItemCategoryId,
      calenderOpen,
      searchedData,
      selectedPoid,
      PaymentTerm,
      PaymentTermId,
      Remark,
      poNum,
      onlyDialog,
      onlyUi,
      downloadPdf,
      specificationArr,
      specificationArrCopy,
      confirmDel,
      pushNoti,
      submittedSpecifies,
      confirmAddWithoutSpecification,
      moveToShipHeader2,
      soitemsList,
      submittedData,
      selectedSOItems,
      submittedItemData,
      masters,
    } = this.state;
    const { BrokerList } = masters;
    console.log(BrandList, "mastallers");
    const { widget_permission } = getSingleModule_widget(
      this.props.user,
      accessText.purchase_order,
      accessText.purchase_order_detail
    );
    // const { user } = this.props;
    var tableLen = this.props.poItemsArrayTOPreviewCopy.length;

    const steps = ["Add Item Details", "Add Multiple Specifications"];

    const handleConfirm = () => {
      this.setState({
        pushNoti: true,
      });
      this.props
        .PurchaseOrdersFunction(
          "delete",
          "dispatch/purchase-order/" + singlelistView.id,
          null,
          singlelistView.id,
          null
        )
        .then((res) => {
          if (res === "Purchase order has been deleted") {
            this.setState({
              // pushNoti: true,
              snackDel: "Purchase Order Deleted",
            });
            this.props.PurchaseOrdersFunction(
              "get",
              EndPoints["PurchaseOrders"],
              null,
              null,
              null
            );
          }
          if (res.toString()[0] === "E") {
            this.setState({
              pushNoti: true,
              confirmDel: false,
              snackDel: "Purchase Order cannot be Deleted",
            });
          }
        });
    };
    const scrollDiv = (id) => {
      const chat = document.getElementById(id);
      if (chat) {
        chat.scrollIntoView();
      }
    };
    const editFunct = (singleDate) => {
      const newArr = [];
      poItemsArrayCopy.map((singleItem) => {
        if (singleItem.po_item_id === singleDate.po_item_id) {
        } else {
          newArr.push(singleItem);
        }
        return singleItem;
      });
      this.props.setPoItems(null, newArr);
      console.log(singleDate,"drtfgyuhjik");
      console.log(BrandList,"BrandList")
      this.setState(
        {
          defaultStep: 1,
          currentStep: 1,
          specificationArr: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                return ss["conditionId"]
                  ? { ...ss, lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id }
                  : {
                      ...ss,
                      Condition: getSpecificationId(ss["condition"]),

                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          specificationArrCopy: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                return ss["conditionId"]
                  ? { ...ss, lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id }
                  : {
                      ...ss,
                      Condition: getSpecificationId(ss["condition"]),
                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          selectedPoid: singleDate.po_item_id,
          itemsId: singleDate["Item Typeid"],
          ItemType: `${singleDate["Item Type"].toString().includes("%")?singleDate["Item Type"].toString():`${singleDate["Item Type"].toString()} %`}`,
          ItemCategoryId: singleDate["Item Categoryid"],
          ItemCategory: singleDate["Item Category"],
          packagingArr: singleDate.packagingArr,
          BrandNameId: singleDate.Brandid,
          BrandName: singleDate.Brandid,
          HSN_id: singleDate.hsn_code,
          HSN: singleDate["HSN Code"],
          total_quantity: singleDate["Total Item Quantity"],
          Rate: singleDate["Contract Rate"],
          selectedBrandMultipleName: `${singleDate["Brand"]}-${singleDate["Item Category"]}-${singleDate["Item Type"]}`,
        },
        () => {}
      );
    };
    const colorArr = [white, lightGrey];
    const addItemsFunction = () => {
      // this.setState({
      //   defaultStep: 0,
      //   currentStep: 0,
      //   errorMsg: {},
      //   submittedSpecifies: {},
      // });
      const newpoItemsArray = poItemsArrayCopy;
      const addData = {
        packaging: packagingArr.map((sP) => {
          return sP.id;
        }),
        brand:  BrandName&&!Array.isArray(BrandName)&&BrandName.length>0?BrandName.map((b)=>b.brand_name):null,
        brandList:BrandName,
        packagingArr: packagingArr,
        cash_discount: CashDiscountId,
        broker: BorkerNameId,
        hsn_code: HSN_id,
        item_type: itemsId,
        item_category: ItemCategoryId,
        total_quantity: total_quantity,
      };
console.log(BrandName,"BrandNamItemTypee",ItemType)
      addData[poItemheader[1]] = ItemCategory;
      addData[`${poItemheader[1]}id`] = ItemCategoryId;
      addData[poItemheader[2]] = ItemType;
      addData[`${poItemheader[2]}id`] = itemsId;
      addData[poItemheader[0]] = BrandName&&Array.isArray(BrandName)&&BrandName.length>0?BrandName.map((b)=>b.brand_name).join(", "):"";
      addData[`${poItemheader[0]}id`] = BrandName&&Array.isArray(BrandName)&&BrandName.length>0?BrandName:null;
      addData[poItemheader[3]] = packagingArr
        .map((sP) => {
          return `${sP.size} ${sP.unit}`;
        })
        .join(" ,");
      addData[`${poItemheader[3]}Id`] = packagingArr.map((sP) => {
        sP.sizeUnit = `${sP.size} ${sP.unit}`;
        return sP;
      });
      addData[`packagageArrUsed`] = packagingArr.map((sP) => {
        sP.sizeUnit = `${sP.size} ${sP.unit}`;
        return sP;
      });
      addData[poItemheader[4]] = HSN;
      addData[`${poItemheader[4]}id`] = HSN_id;
      addData[poItemheader[5]] = total_quantity;
      addData[`${poItemheader[5]}Unit`] = `${total_quantity} ${
        Unit ? Unit : ""
      }`;

      addData[poItemheader[6]] = Rate;

      addData["specificationArr"] = specificationArr;
      addData["Specifications"] = specificationArr;
      addData["specifications"] = specificationArr;
      addData[`${poItemheader[6]}Unit`] = `${Rate}/${Unit ? Unit : ""}`;
      addData.po_item_id = selectedPoid
        ? selectedPoid
        : newpoItemsArray.length + 1;
      addData.id = selectedPoid ? selectedPoid : newpoItemsArray.length + 1;
      addData.selectedBrandMultipleName = selectedBrandMultipleName
        ? selectedBrandMultipleName
        : "";
      const newArr = [];

      if (newpoItemsArray.length > 0) {
        newpoItemsArray.map((s) => {
          if (s.id === addData.id) {
            newArr.push(addData);
          } else {
            newArr.push(s);
          }
          return s;
        });
        if (selectedPoid === false) {
          newArr.push(addData);
        }
      } else {
        newArr.push(addData);
      }
      console.log(addData,"addData")










      
      this.props.setPoItems(newArr, newArr);
      this.setState({
        itemsId: "",
        ItemType: "",
        ItemCategoryId: "",
        ItemCategory: "",
        BrandNameId: "",
        BrandName: false,
        selectedBrandMultipleName: false,
        HSN_id: "",
        HSN: "",
        total_quantity: "",
        selectedPoid: false,
        Rate: "",
        specificationArr: [],
        specificationArrCopy: [],
        defaultStep: 0,
        currentStep: 0,
        packagingArr: [],
      });
    };
    {
      console.log(
 
        ItemType,
        masters["ItemList"],
        "jjjjjjjjjjjjjjjjjjjjjjj"
      );
    }
    const SpecificationTable = () => {
      return (
        <div className="d-flex width-100 flexWrap justifyC">
          <div className="width-100 " style={{ margin: "20px 10px" }}>
            <div
              style={{
                backgroundColor: "rgba(251, 224, 191, 1)",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}>
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgba(241, 154, 48, 1)" }}>
                {specificationHeader.map((sC) => (
                  <div
                    key={sC.name}
                    className="justifyC d-flex alignC"
                    style={{
                      width: "calc(25% - 54px)",
                      margin: "12px 10px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}>
                    {sC.name}
                  </div>
                ))}{" "}
                <div
                  className="justifyC d-flex alignC"
                  style={{
                    margin: "12px 5px",
                    width: "82px",
                    display: "flex",
                    alignItems: "center",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}>
                  Action
                </div>
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                width: "100%",
                minWidth: "100%",
                position: "relative",
                borderRadius: "0 0 7px 7px",
                borderRight: "1px solid rgba(128, 128, 128, 0.64)",
                borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
                borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
                padding: "5px 0",
              }}>
              {specificationArr &&
                specificationArr.map((sA, i) => (
                  <div
                    className={`dividerBorder d-flex width-100 justifySE `}
                    style={{
                      backgroundColor:
                        colorArr && colorArr[i % colorArr.length],
                      height: "44px",
                    }}>
                    {specificationHeader.map((sC) => (
                      <div
                        key={sC.name}
                        className="justifyC d-flex alignC"
                        style={{
                          width: "calc(25% - 54px)",
                          margin: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}>
                        {sA[sC.api]
                          ? `${sA[sC.api]} ${
                              sC.api === "value"
                                ? sA["name"] === "Moisture" ||
                                  sA["name"] === "Broken" ||
                                  sA["name"] === "Damaged/Coloured" ||
                                  sA["name"] === "Chalky"
                                  ? "%"
                                  : sA["name"] === "Length"
                                  ? "MM"
                                  : sA["name"] === "Paddy"
                                  ? `/${Unit}`
                                  : ""
                                : ""
                            }`
                          : sC.api === "name"
                          ? `${sA["name"]}`
                          : "-"}
                      </div>
                    ))}{" "}
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "10px",
                        width: "82px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        const submittedData = {};
                        specificationHeader.map((singleInput) => {
                          submittedData[singleInput.name] = sA[singleInput.api];
                          submittedData[`${singleInput.name}Id`] =
                            sA[`${singleInput.api}Id`];

                          this.setState({
                            errorMsg: {
                              ...errorMsg,
                              [singleInput.name]: false,
                            },
                          });
                          return singleInput;
                        });

                        const arr = [];
                        specificationArrCopy.map((s) => {
                          if (sA.lid === s.lid) {
                          } else {
                            arr.push(s);
                          }
                          return s;
                        });

                        this.setState({
                          submittedSpecifies: {
                            ...submittedData,
                            lid:
                              sA.lid || sA.lid === 0
                                ? sA.lid
                                : specificationArrCopy.length + 1,
                          },
                          specificationArr: arr,

                          errorMsg: { ...errorMsg, addSpecification: false },
                        });
                      }}>
                      <StyledButton
                        name={<EditIcon />}
                        toolTip="Edit"
                        maxHeight="24px"
                        padding="0"
                        height="24px"
                        width="40px"
                        minWidth="0"
                      />
                    </div>
                  </div>
                ))}{" "}
              <div
                className="d-flex width-100 justifySE"
                style={{
                  backgroundColor:
                    colorArr &&
                    colorArr[specificationArr.length % colorArr.length],
                  minHeight: "60px",
                }}>
                {specificationHeader.map((singleInput, i) =>
                  singleInput.type === "select" ? (
                    <div style={{ width: "calc(25% - 54px)", margin: "10px" }}>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          color: "rgb(76, 89, 103)",
                          fontWeight: 500,
                          margin: "0 0 5px 0",
                        }}>{`${
                        singleInput.name.charAt(0).toUpperCase() +
                        singleInput.name.slice(1)
                      } ${
                        singleInput.not_required === true ? "" : "*"
                      }`}</div>{" "}
                      <CssTextField95
                        required={true}
                        error={errorMsg[singleInput.name] ? true : false}
                        autoComplete="off"
                        value={
                          submittedSpecifies[singleInput.name]
                            ? submittedSpecifies[singleInput.name]
                            : ""
                        }
                        style={{ width: "100%" }}
                        select
                        // label={
                        //   singleInput.name.charAt(0).toUpperCase() +
                        //   singleInput.name.slice(1)
                        // }
                        SelectProps={{ disableunderline: "true" }}
                        variant="outlined"
                        onChange={(e) => {
                          if (e.target.value) {
                            const id = `${singleInput.name}Id`;
                            this.setState({
                              submittedSpecifies: {
                                ...submittedSpecifies,
                                [singleInput.name]: e.target.value,
                                [id]: e.currentTarget.id,
                              },
                              errorMsg: {
                                ...errorMsg,
                                [singleInput.name]: false,
                              },
                            });
                          }
                        }}>
                        {singleInput &&
                        singleInput.array &&
                        singleInput.array.length > 0 ? (
                          singleInput.filter ? (
                            singleInput.filter(
                              singleInput.array,
                              specificationArr
                            ) &&
                            singleInput.filter(
                              singleInput.array,
                              specificationArr
                            ).length > 0 ? (
                              singleInput
                                .filter(singleInput.array, specificationArr)
                                .map((option) => (
                                  <MenuItem
                                    style={{ textTransform: "capitalize" }}
                                    key={option[singleInput.value]}
                                    value={option[singleInput.value]}
                                    id={
                                      option.id
                                        ? option.id
                                        : option[singleInput.value]
                                    }>
                                    {singleInput.endAdornment
                                      ? `${option[singleInput.value]} ${
                                          singleInput.endAdornment
                                        }`
                                      : option[singleInput.value]}
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={`No type is remaining.`}
                                value={`No type is remaining.`}
                                id={`No type is remaining.`}>
                                {`No type is remaining.`}
                              </MenuItem>
                            )
                          ) : (
                            singleInput.array.map((option) => (
                              <MenuItem
                                style={{ textTransform: "capitalize" }}
                                key={option[singleInput.value]}
                                value={option[singleInput.value]}
                                id={
                                  option.id
                                    ? option.id
                                    : option[singleInput.value]
                                }>
                                {singleInput.endAdornment
                                  ? `${option[singleInput.value]} ${
                                      singleInput.endAdornment
                                    }`
                                  : option[singleInput.value]}
                              </MenuItem>
                            ))
                          )
                        ) : (
                          <MenuItem
                            disabled={true}
                            key={`No ${singleInput.name} added yet`}
                            value={`No ${singleInput.name} added yet`}
                            id={`No ${singleInput.name} added yet`}>
                            {`No ${singleInput.name} added yet`}
                          </MenuItem>
                        )}
                      </CssTextField95>
                    </div>
                  ) : (
                    <div style={{ width: "calc(25% - 54px)", margin: "10px" }}>
                      {labelText(
                        singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1),
                        singleInput.not_required
                      )}
                      <CssTextField95
                        autoFocus={singleInput.autoFocus ? true : false}
                        error={errorMsg[singleInput.name] ? true : false}
                        autoComplete="off"
                        InputProps={{
                          endAdornment:
                            submittedSpecifies["Type"] === "Moisture" ||
                            submittedSpecifies["Type"] === "Broken" ||
                            submittedSpecifies["Type"] === "Damaged/Coloured" ||
                            submittedSpecifies["Type"] === "Chalky"
                              ? "%"
                              : submittedSpecifies["Type"] === "Length"
                              ? "MM"
                              : submittedSpecifies["Type"] === "Paddy"
                              ? `/${Unit}`
                              : "",
                        }}
                        // InputProps={{
                        //   inputProps: {
                        //     min: 0,
                        //   },
                        //   endAdornment:
                        //     submittedSpecifies["name"] === "Moisture" ||
                        //     submittedSpecifies["name"] === "Broken" ||
                        //     submittedSpecifies["name"] === "Damaged/Coloured" ||
                        //     submittedSpecifies["name"] === "Chalky"
                        //       ? "%"
                        //       : submittedSpecifies["name"] === "Length"
                        //       ? "MM"
                        //       : submittedSpecifies["name"] === "Paddy"
                        //       ? `/${Unit}`
                        //       : "",
                        // }}
                        onFocus={StopScroll}
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        type={singleInput.dataType}
                        value={
                          submittedSpecifies[singleInput.name]
                            ? submittedSpecifies[singleInput.name]
                            : ""
                        }
                        name={singleInput.name}
                        onKeyDown={(evt) => {
                          if (singleInput.only_number === true) {
                            evt.key === "." && evt.preventDefault();
                          }
                          if (singleInput.dataType === "number") {
                            evt.key === "e" && evt.preventDefault();

                            evt.key === "E" && evt.preventDefault();
                            evt.key === "-" && evt.preventDefault();
                            evt.keyCode === 38 && evt.preventDefault();
                            evt.keyCode === 40 && evt.preventDefault();
                          }
                        }}
                        onInput={(e) => {
                          if (singleInput.only_number === true) {
                            e.target.value = e.target.value
                              .replace(/[^\w]|_/g, "")
                              .toLowerCase();
                          }
                        }}
                        onChange={(event) => {
                          this.setState({
                            submittedSpecifies: {
                              ...submittedSpecifies,
                              [singleInput.name]:
                                event.target.value && singleInput.upperCase
                                  ? event.target.value.toUpperCase()
                                  : event.target.value,
                            },
                            errorMsg: {
                              ...errorMsg,
                              [singleInput.name]: false,
                            },
                          });
                        }}
                        variant="outlined"
                        // className="textC"
                      />
                    </div>
                  )
                )}
                <div
                  className="justifySA d-flex"
                  style={{
                    margin: "10px",
                    width: "82px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}>
                  {" "}
                  <StyledButton
                    onClick={() => {
                      const newObj = {};
                      const correct = [];
                      const er = { ...errorMsg };
                      specificationHeader.map((singleInput, i) => {
                        if (submittedSpecifies[singleInput.name]) {
                          correct.push(singleInput);
                          newObj[singleInput.api] =
                            submittedSpecifies[singleInput.name];
                          newObj[`${singleInput.api}Id`] =
                            submittedSpecifies[`${singleInput.name}Id`];
                          if (i === 1) {
                            newObj["Condition"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`ConditionId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                          if (i === 0) {
                            newObj["Type"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`TypeId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                            newObj["name"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`nameId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                        } else {
                          er[singleInput.name] = `Please add !`;
                        }
                      });
                      if (correct.length === 3) {
                        const arr = specificationArr;
                        arr.push({
                          ...newObj,
                          lid:
                            submittedSpecifies.lid ||
                            submittedSpecifies.lid === 0
                              ? submittedSpecifies.lid
                              : specificationArrCopy.length + 1,
                        });

                        this.setState({
                          specificationArr: arr,
                          specificationArrCopy: arr,
                          submittedSpecifies: {},
                          errorMsg: {},
                        });
                      } else {
                        this.setState({ errorMsg: er });
                      }
                    }}
                    background={
                      errorMsg["addSpecification"] === true ? red : ""
                    }
                    withBg={errorMsg["addSpecification"] === true}
                    name={<Add />}
                    toolTip="Add"
                    maxHeight="37.99px"
                    padding="0"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />{" "}
                  <StyledButton
                    name={<CrossIcon color={primary} strokeWidth="2" />}
                    toolTip="Clear"
                    maxHeight="37.99px"
                    onClick={() => {
                      if (
                        submittedSpecifies &&
                        (submittedSpecifies["Type"] ||
                          submittedSpecifies["Condition"] ||
                          submittedSpecifies["Value"])
                      ) {
                        this.setState({ submittedSpecifies: {}, errorMsg: {} });
                      } else {
                        this.setState({
                          error: "Nothing to Clear",
                          errorMsg: {},
                        });
                      }
                    }}
                    padding="0"
                    margin="0 0 0 2px"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            id="update-button"
            className="d-flex alignC justifyFE width-100"
            style={{
              margin: "10px",
              // width: "calc(25% - 20px)",
            }}>
            {/* {confirmAddWithoutSpecification === true ? (
              <ConfirmDialog
                onSubmit={(e) => {
                  e.stopPropagation();
                  addItemsFunction();
                  this.setState({ confirmAddWithoutSpecification: false });
                }}
                resetText={"No"}
                reset={() => {
                  this.setState({
                    confirmAddWithoutSpecification: false,
                    errorMsg: { ...errorMsg, addSpecification: true },
                  });
                }}
                height="300px"
                width="250px"
                action={"Yes"}
                setOpenDialog={() => {
                  this.setState({ confirmAddWithoutSpecification: false });
                }}
                openDialog={
                  confirmAddWithoutSpecification !== false ? true : false
                }
                content={() => {
                  return (
                    <div className="width-100 height-100">
                      <HeadingLine
                        maxWidth="100%"
                        width="100%"
                        subHeading={true}
                        children={`You are adding items without any specifications!`}
                        margin="0 0 10px"
                        // withBg={true}
                      />
                    </div>
                  );
                }}
              />
            ) : (
              ""
            )} */}
            <StyledButton
              onClick={() => {
                // if (BrandName !== false) {
                if (ItemCategory) {
                  if (ItemType) {
                    if (packagingArr.length > 0) {
                      if (HSN) {
                        if (total_quantity) {
                          if (Rate) {
                            if (specificationArr.length > 0) {
                              this.setState(
                                {
                                  defaultStep: 0,
                                  currentStep: 0,
                                  errorMsg: {},
                                  submittedSpecifies: {},
                                },
                                () => {
                                  addItemsFunction();
                                }
                              );
                            } else if (
                              submittedSpecifies &&
                              (submittedSpecifies["Type"] ||
                                submittedSpecifies["Condition"] ||
                                submittedSpecifies["Value"])
                            ) {
                              this.setState({
                                errorMsg: {
                                  ...errorMsg,
                                  addSpecification: true,
                                },
                              });
                            } else {
                              this.setState(
                                {
                                  defaultStep: 0,
                                  currentStep: 0,
                                  errorMsg: {},
                                  submittedSpecifies: {},
                                },
                                () => {
                                  addItemsFunction();
                                }
                              );
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("rate");
                            errorMsg.Rate = "Please give an Contract rate!";

                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("total-quantity");
                          errorMsg.total_quantity =
                            "Please give an total_quantity!";

                          this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        scrollDiv("hsn");
                        errorMsg.HSN = "Please give an HSN!";

                        this.setState({ errorMsg });
                      }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      scrollDiv("packaging");
                      errorMsg.packagingArr = "Please give packaging!";
                      this.setState({ errorMsg });
                    }
                  } else {
                    const errorMsg = this.state.errorMsg;
                    scrollDiv("brand");
                    errorMsg.ItemType = "Please select an ItemType!";
                    this.setState({ errorMsg });
                  }
                } else {
                  const errorMsg = this.state.errorMsg;
                  scrollDiv("brand");
                  errorMsg.ItemCategory = "Please select an ItemCategory!";
                  this.setState({ errorMsg });
                }
                // } else {
                //   const errorMsg = this.state.errorMsg;
                //   errorMsg.BrandName = "Please give an BrandName!";
                //   scrollDiv("brand");
                //   this.setState({ errorMsg });
                // }
              }}
              name={`${editDialog !== false ? "Update" : "Add"} Item`}
              minWidth="30px"
              width="150px"
              margin="0 10px"
              withBg={true}
              background={errorMsg["updatedButton"] ? red : primary}
            />
          </div>
        </div>
      );
    };
    return (
      <div style={onlyDialog !== true ? styles.mainDiv : styles.none}>
        {error ||success? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              this.setState({
                error: "",
              });
            }}
          />
        ) : (
          ""
        )}
        {onlyUi === true && editDialog === false ? (
          ""
        ) : openDialog === true || editDialog !== false ? (
          <Dialog
            disableEnforceFocus
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100vh - 64px)";
                target.style.minHeight = "calc(100vh - 64px)";

                target.style.height = "calc(100vh - 64px)";
                target.style.minWidth = "calc(100% - 100px)";
                target.style.maxWidth = "calc(100% - 100px)";
                target.style.margin = "32px 50px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.props.closeDialog();
              this.setState(
                {
                  openDialog: false,
                  editDialog: false,
                  added: false,
                },
                () => {
                  this.reset("no snac");
                  this.handleClearFields();
                }
              );
            }}
            TransitionComponent={Transition}>
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}>
              {" "}
              <h3 style={styles.heading}>
                {" "}
                <PurchaseIcon style={{ color: "white", margin: "0 5px 0 0" }} />
                {editDialog ? "Edit " : "Create "}Purchase Order
              </h3>
              <Tooltip title="Relaod" arrow placement="top">
                <IconButton
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                  onClick={(e) => {
                    this.props.setreload(true);
                  }}>
                  <Reload style={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="close" arrow placement="top">
                <IconButton
                  style={{ padding: "5px", borderRadius: "5px" }}
                  onClick={() => {
                    this.props.closeDialog();
                    this.reset("no snac");
                    this.handleClearFields();
                    this.setState({
                      openDialog: false,
                      editDialog: false,
                      added: false,
                    });
                  }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>{" "}
            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}>
              {" "}
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  autoHideDuration={error ? 5000 : 2000}
                  snackbarClose={() => {
                    if (
                      error === "Nothing to reset !" ||
                      success === "Reset successfully !"
                    ) {
                      this.setState({
                        error: "",
                        success: "",
                        loadingFun: false,
                      });
                    } else {
                      if (
                        success === "Purchase Order Added!" ||
                        success === "Purchase Order Updated!"
                      ) {
                        this.props.closeDialog();
                        this.reset("no snac");
                        this.handleClearFields();
                        this.setState({
                          openDialog: false,
                          editDialog: false,
                          error: "",
                          success: "",
                          loadingFun: false,
                        });
                      } else if (error) {
                        this.setState({
                          error: "",
                        });
                      }
                    }
                  }}
                />
              ) : (
                ""
              )}
              {added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "rgb(241 154 48)",
                    }}
                  />
                  <span
                    className="heading"
                    style={{ color: "rgb(241 154 48)", margin: "10px 0 0 0" }}>
                    {added}
                  </span>
                </div>
              ) : (
                <>
                  {" "}
                  {loadingFun || this.state.loader ? (
                    <Backdrop
                      style={{ zIndex: 1, color: "#fff" }}
                      open={loadingFun}>
                      <StyledCircularProgress />
                    </Backdrop>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "10px 0",
                      minHeight: "71.91px",
                    }}>
                    {/* <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("PO Number")}
                      <CssTextField
                        autoComplete="off"
                        required={true}
                        id="outlined-basic"
                        error={errorMsg.poNum ? true : false}
                        helperText={
                          errorMsg.poNum ? "Enter valid PO Number" : false
                        }
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        // label="PO Number"
                        name="poNum"
                        value={poNum}
                        onChange={this.handleChange}
                        variant="outlined"
                        // disabled={true}
                      />
                    </div> */}
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Contract Date")}
                      <MuiPickersUtilsProvider
                        libInstance={moment()}
                        utils={DateFnsUtils}>
                        <CssKeyboardDatePicker
                          // label={"Contract Date"}
                          variant="inline"
                          onOpen={() => {
                            this.setState({ calenderOpen: true });
                          }}
                          onClose={() => {
                            this.setState({ calenderOpen: false });
                          }}
                          maxDate={new Date()}
                          disableFuture
                          error={false}
                          invalidLabel=""
                          onKeyPress={(ev) => {
                            ev.preventDefault();
                          }}
                          onKeyDown={(evt) => {
                            evt.keyCode === 8 && evt.preventDefault();
                          }}
                          allowKeyboardControl={false}
                          keyboardIcon={
                            calenderOpen ? <TopArrow /> : <BottomArrow />
                          }
                          autoOk
                          inputVariant="outlined"
                          style={
                            { width: "100%" } //disabled={tp}
                          }
                          format="do MMM, yyyy"
                          //  style={
                          //   styles.withDivider50 //disabled={tp}
                          // }
                          value={searchedData ? searchedData : new Date()}
                          //views={['year', 'month']}
                          InputAdornmentProps={{ position: "end" }}
                          onChange={(newValue) => {
                            this.setState({ searchedData: newValue });
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Status")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          selctedPOStatus ? selctedPOStatus : "" //
                        }
                        disabled={editDialog === false ? true : false}
                        error={errorMsg.selctedPOStatus ? true : false}
                        helperText={
                          errorMsg.selctedPOStatus
                            ? errorMsg.selctedPOStatus
                            : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.selctedPOStatus = false;
                          this.setState({
                            errorMsg,
                            selctedPOStatus: e.target.value,
                          });
                        }}
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        select
                        // label="Status"
                        variant="outlined"
                        InputLabelProps={{}}>
                        {status &&
                          status.length > 0 &&
                          status.map((option) => (
                            <MenuItem
                              key={option.name.toString()}
                              value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div>
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Unit")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          Unit ? Unit : "" //
                        }
                        error={errorMsg.Unit ? true : false}
                        helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.Unit = false;
                          if (editDialog !== false) {
                            this.setData(e.target.value ? e.target.value : "");
                          } else {
                            this.props.setPoItems(
                              poItemsArrayCopy.map((ss) => {
                                return {
                                  ...ss,
                                  [`${poItemheader[5]}Unit`]: `${
                                    ss[poItemheader[5]]
                                  } ${e.target.value ? e.target.value : ""}`,
                                };
                              }),
                              poItemsArray.map((ss) => {
                                return {
                                  ...ss,
                                  [`${poItemheader[5]}Unit`]: `${
                                    ss[poItemheader[5]]
                                  } ${e.target.value ? e.target.value : ""}`,
                                };
                              })
                            );
                          }
                          this.setState({
                            errorMsg,
                            Unit: e.target.value,
                          });
                        }}
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        id="unit"
                        disabled={
                          SalesOrder
                            ? true
                            : this.props.onlyCreate
                            ? true
                            : false
                        }
                        select
                        //   label="Unit"
                        variant="outlined"
                        InputLabelProps={{}}>
                        {unit &&
                          unit.length > 0 &&
                          unit.map((option) => (
                            <MenuItem
                              key={option.name.toString()}
                              value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div>
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Contract Type")}
                      <CssTextField
                        required={true}
                        disabled= {onlyCreate && onlyCreate.contract_type ? true : false}
                        // disabled={this.props.onlyCreate &&this.props.onlyCreate.contract_type === false ? false : true}
                        autoComplete="off"
                        value={
                          contractTypeName ? contractTypeName : "" //
                        }
                        error={errorMsg.contractTypeName ? true : false}
                        helperText={
                          errorMsg.contractTypeName
                            ? errorMsg.contractTypeName
                            : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.contractTypeName = false;
                          this.setState({
                            errorMsg,
                            contractTypeId: e.currentTarget.id,
                            contractTypeName: e.target.value,
                          });
                        }}
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        id="contract-type"
                        select
                        //  label="Contract Type"
                        variant="outlined">
                        {ContractTypeList &&
                          ContractTypeList.length > 0 &&
                          ContractTypeList.map((option) => (
                            <MenuItem
                              key={option.contract_type.toString()}
                              value={option.contract_type}
                              id={option.id}>
                              {option.contract_type}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div>
                 
                  </div>
                  <div
                    style={
                      {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "1rem",
                        color: "grey",
                        background: "white",
                        margin: "10px 0",
                        minHeight: "71.91px",
                      } //height: "100%",
                    }>
                         <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Broker Name")}
                      <CssTextField
                        id="broker"
                        required={true}
                        autoComplete="off"
                        value={
                          BorkerName ? BorkerName : "" //
                        }
                        error={errorMsg.BorkerName ? true : false}
                        helperText={
                          errorMsg.BorkerName ? errorMsg.BorkerName : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.BorkerName = false;

                          this.setState({
                            errorMsg,

                            Borkerage: filterData(
                              BrokerList,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  BrokerList,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                ).default_brokerage
                              : "",

                            BorkerNameId: e.currentTarget.id,

                            BorkerageId: e.currentTarget.id,
                            BorkerName: e.target.value,
                          });
                        }}
                        disabled={
                          onlyCreate && onlyCreate.broker_profile ? true : false
                        }
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        select
                        //     label="Broker"
                        variant="outlined"
                        InputLabelProps={{}}>
                        {BrokerList &&
                          BrokerList.length > 0 &&
                          BrokerList.map((option) => (
                            <MenuItem
                              key={option.name.toString()}
                              value={option.name}
                              id={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div>
                    {/* <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Contract Type")}
                      <CssTextField
                        required={true}
                        disabled={editDialog === false ? false : true}
                        autoComplete="off"
                        value={
                          contractTypeName ? contractTypeName : "" //
                        }
                        error={errorMsg.contractTypeName ? true : false}
                        helperText={
                          errorMsg.contractTypeName
                            ? errorMsg.contractTypeName
                            : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.contractTypeName = false;
                          this.setState({
                            errorMsg,
                            contractTypeId: e.currentTarget.id,
                            contractTypeName: e.target.value,
                          });
                        }}
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        id="contract-type"
                        select
                        //  label="Contract Type"
                        variant="outlined">
                        {ContractTypeList &&
                          ContractTypeList.length > 0 &&
                          ContractTypeList.map((option) => (
                            <MenuItem
                              key={option.contract_type.toString()}
                              value={option.contract_type}
                              id={option.id}>
                              {option.contract_type}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div> */}
                    {/* <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Broker Name")}
                      <CssTextField
                        id="broker"
                        required={true}
                        autoComplete="off"
                        value={
                          BorkerName ? BorkerName : "" //
                        }
                        error={errorMsg.BorkerName ? true : false}
                        helperText={
                          errorMsg.BorkerName ? errorMsg.BorkerName : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.BorkerName = false;

                          this.setState({
                            errorMsg,

                            Borkerage: filterData(
                              BrokerList,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  BrokerList,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                ).default_brokerage
                              : "",

                            BorkerNameId: e.currentTarget.id,

                            BorkerageId: e.currentTarget.id,
                            BorkerName: e.target.value,
                          });
                        }}
                        disabled={
                          onlyCreate && onlyCreate.broker_profile ? true : false
                        }
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        select
                        //     label="Broker"
                        variant="outlined"
                        InputLabelProps={{}}>
                        {BrokerList &&
                          BrokerList.length > 0 &&
                          BrokerList.map((option) => (
                            <MenuItem
                              key={option.name.toString()}
                              value={option.name}
                              id={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextField>
                    </div> */}
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Port")}
                      <MultipleSelectBar
                        required={true}
                        id="port"

                        noLabel={true}
                        width={"100%"}
                        // margin={"10px"}
                        value={portArry.map((sP) => {
                          sP.nameCity = `${sP.name} - ${sP.city}`;
                          return sP;
                        })}
                        array={
                          PortsList && PortsList.length > 0
                            ? PortsList.map((sP) => {
                                sP.nameCity = `${sP.name} - ${sP.city}`;
                                return sP;
                              })
                            : []
                        }
                        error={errorMsg.portArry ? true : false}
                        helperText={errorMsg.portArry ? errorMsg.portArry : ""}
                        selectedArr={(val) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.portArry = false;
                          this.setState({ portArry: val, errorMsg });
                        }}
                        title="nameCity"
                      />
                    </div>
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Miller Name")}
                      <CssTextField
                        id="miller"
                        required={true}
                        autoComplete="off"
                        value={MillerName ? MillerName : ""}
                        error={errorMsg.MillerName ? true : false}
                        helperText={
                          errorMsg.MillerName ? errorMsg.MillerName : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.MillerName = false;
                          this.setState({
                            errorMsg,
                            MillerNameId: e.currentTarget.id,
                            MillerName: e.target.value,
                            MillerAddress: filterData(
                              MillerList,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  MillerList,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                ).address
                              : "",
                            MillerGST: filterData(
                              MillerList,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  MillerList,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                ).gstin
                              : "",
                            ContactPerson: filterData(
                              MillerList,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  MillerList,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                ).contact_person
                              : "",
                          });
                        }}
                        style={{ width: "100%" }}
                        select
                        disabled={
                          onlyCreate && onlyCreate.miller_profile ? true : false
                        }
                        //    label="Miller Name"
                        variant="outlined">
                        {MillerList &&
                          MillerList.length > 0 &&
                          MillerList.map((option) => (
                            <MenuItem
                              key={option.name.toString()}
                              value={option.name}
                              id={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextField>{" "}
                    </div>
                    <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Miller Address")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          MillerAddress ? MillerAddress : "" //
                        }
                        error={errorMsg.MillerAddress ? true : false}
                        helperText={
                          errorMsg.MillerAddress ? errorMsg.MillerAddress : ""
                        }
                        disabled={true}
                        style={{
                          width: "100%",
                        }}
                        //  label="Miller Address"
                        variant="outlined"
                      />
                    </div>
                   
                  </div>
                  <div
                    style={
                      {
                        display: "flex",
                        fontSize: "1rem",
                        color: "grey",
                        background: "white",
                        marginTop: "10px",
                        minHeight: "71.91px",
                      } //height: "100%",
                    }>
                       <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Miller GST")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          MillerGST ? MillerGST : "" //
                        }
                        error={errorMsg.MillerGST ? true : false}
                        helperText={
                          errorMsg.MillerGST ? errorMsg.MillerGST : ""
                        }
                        style={
                          {
                            // width: "calc(25% - 20px)",
                            // margin: "10px 10px 0px 10px",
                            width: "100%",
                          } //disabled={tp}
                        }
                        //  label="Miller GST"
                        variant="outlined"
                        disabled={true}
                      />
                    </div>
                    {/* <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Miller Address")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          MillerAddress ? MillerAddress : "" //
                        }
                        error={errorMsg.MillerAddress ? true : false}
                        helperText={
                          errorMsg.MillerAddress ? errorMsg.MillerAddress : ""
                        }
                        disabled={true}
                        style={{
                          width: "100%",
                        }}
                        //  label="Miller Address"
                        variant="outlined"
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Miller GST")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        value={
                          MillerGST ? MillerGST : "" //
                        }
                        error={errorMsg.MillerGST ? true : false}
                        helperText={
                          errorMsg.MillerGST ? errorMsg.MillerGST : ""
                        }
                        style={
                          {
                            // width: "calc(25% - 20px)",
                            // margin: "10px 10px 0px 10px",
                            width: "100%",
                          } //disabled={tp}
                        }
                        //  label="Miller GST"
                        variant="outlined"
                        disabled={true}
                      />
                    </div> */}
                    <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Contact Person")}
                      <CssTextField
                        required={true}
                        autoComplete="off"
                        id="contact-person"
                        value={
                          ContactPerson ? ContactPerson : "" //
                        }
                        disabled={true}
                        error={errorMsg.ContactPerson ? true : false}
                        helperText={
                          errorMsg.ContactPerson ? errorMsg.ContactPerson : ""
                        }
                        style={
                          {
                            width: "100%",
                            // width: "calc(25% - 20px)",
                            // margin: "10px 10px 0px 10px",
                          } //disabled={tp}
                        }
                        //  label="Contact Person"
                        variant="outlined"
                        InputLabelProps={{}}
                      />
                    </div>
                    {/* {editDialog !== false && (
                      <div
                        style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                        {labelText("Last Survey")}
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}>
                          <CssKeyboardDatePicker
                            variant="inline"
                            style={
                              { width: "100%" } //disabled={tp}
                            }
                            onOpen={() => {
                              this.setState({ calenderOpenLS: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpenLS: false });
                            }}
                            maxDate={new Date()}
                            disableFuture
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpenLS ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            // label={"Last Survey"}
                            format="do MMM, yyyy"
                            value={last_survey ? last_survey : new Date()}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ last_survey: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )} */}
                    {/* <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Sales Order", true)}
                      <CssTextField
                        id="sales_order"
                        autoComplete="off"
                        value={
                          SalesOrder ? SalesOrder : "" //
                        }
                        error={errorMsg.SalesOrder ? true : false}
                        helperText={
                          errorMsg.SalesOrder ? errorMsg.SalesOrder : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.SalesOrder = false;
                          const selectedSo = filterData(
                            SalesOrderList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                SalesOrderList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              )
                            : {};
                          this.setState({
                            errorMsg,
                            SalesOrder: e.target.value,
                            selectedSOItems: [],
                            submittedItemData: {},
                            SalesOrderId: e.currentTarget.id,
                            Unit: selectedSo.unit,
                            UnitId: selectedSo.unit,
                          });
                          if (e.target.value) {
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                "dispatch/so-items/list",
                                null,
                                null,
                                null,
                                {
                                  so_id: e.currentTarget.id,
                                }
                              )
                              .then((soitems) => {
                                if (soitems && soitems.length > 0) {
                                  const soDetail = {};
                                  soitems.map((s) => {
                                    soDetail[s.id] = s.total_quantity ?? 0;
                                    return s;
                                  });

                                  this.setState({
                                    soitemsList: soitems,
                                    submittedItemData: soDetail,
                                  });
                                }
                              });
                          }
                        }}
                        disabled={this.props.onlyCreate}
                        style={
                          {
                            width: "100%",
                          } //disabled={tp}
                        }
                        select
                        //   label="Sales Order Numbers"
                        variant="outlined"
                        InputLabelProps={{}}>
                        {SalesOrderList &&
                          SalesOrderList.length > 0 &&
                          SalesOrderList.sort((a, b) => b.id - a.id).map(
                            (option) => (
                              <MenuItem
                                key={option.so_number}
                                value={option.so_number}
                                id={option.id}>
                                {option.so_number}
                              </MenuItem>
                            )
                          )}
                      </CssTextField>
                    </div> */}
                    <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                      {labelText("Cash Discount",true)}
                      <CssTextField
                        id="cash_discount"
                        required={false}
                        autoComplete="off"
                        style={{
                          width: "100%",
                        }}
                        value={
                          CashDiscount && CashDiscount ? CashDiscount : "" //
                        }
                        // value={
                        //   singlelistView &&
                        //   singlelistView.cash_discount_details &&
                        //   singlelistView.cash_discount_details.length > 0
                        //     ? singlelistView.cash_discount_details.map((data) => {
                        //         data[
                        //           "discountdays"
                        //         ] = `${data.discount} - ${data.expiry_days}`;
                        //         return data;
                        //       })
                        //     : []
                        // }
                        error={errorMsg.CashDiscount ? true : false}
                        helperText={
                          errorMsg.CashDiscount ? errorMsg.CashDiscount : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.CashDiscount = false;

                          this.setState({
                            errorMsg,
                            CashDiscountId: filterData(
                              CashDiscountList,
                              "discount",
                              e.target.value,
                              "onlyOne"
                            )
                              ? filterData(
                                  CashDiscountList,
                                  "discount",
                                  e.target.value,
                                  "onlyOne"
                                ).id
                              : "",
                            CashDiscount: e.target.value,

                            CashDiscountId: e.currentTarget.id,
                          });
                        }}
                       
                        // style={
                        //   { width: "100" } //disabled={tp}
                        // }
                        select
                        //label="Cash Discount"
                        variant="outlined">
                        {CashDiscountList &&
                          CashDiscountList.length > 0 &&
                          CashDiscountList.map((option) => (
                            <MenuItem
                              key={option.discount.toString()}
                              value={option.discount}
                              id={option.id}>
                              
                              {option.discount} {option.discount ? "%" : ""} -
                              {option.expiry_days}{" "}
                              {option.expiry_days > 1 ? "days" : "day"}
                            </MenuItem>
                          ))}
                      </CssTextField>
                   
                    </div>
                    <div
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}>
                      {labelText("Sales Order", true)}
                      <CssNewTextField
                        id="sales_order"
                        autoComplete="off"
                        value={
                          SalesOrder ? SalesOrder : "" //
                        }
                        error={errorMsg.SalesOrder ? true : false}
                        helperText={
                          errorMsg.SalesOrder ? errorMsg.SalesOrder : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.SalesOrder = false;
                          const selectedSo = filterData(
                            SalesOrderList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                SalesOrderList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              )
                            : {};
                          this.setState({
                            errorMsg,
                            SalesOrder: e.target.value,
                            selectedSOItems: [],
                            submittedItemData: {},
                            SalesOrderId: e.currentTarget.id,
                            Unit: selectedSo.unit,
                            UnitId: selectedSo.unit,
                          });
                          if (e.target.value) {
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                "dispatch/so-items/list",
                                null,
                                null,
                                null,
                                {
                                  so_id: e.currentTarget.id,
                                }
                              )
                              .then((soitems) => {
                                if (soitems && soitems.length > 0) {
                                  const soDetail = {};
                                  soitems.map((s) => {
                                    soDetail[s.id] = s.total_quantity ?? 0;
                                    return s;
                                  });

                                  this.setState({
                                    soitemsList: soitems,
                                    submittedItemData: soDetail,
                                  });
                                }
                              });
                          }
                        }}
                        disabled={this.props.onlyCreate}
                        style={
                          {
                            width: "100%",
                          } //disabled={tp}
                        }
                        select
                        //   label="Sales Order Numbers"
                        variant="outlined"
                        helperText={<span style={{cursor:"pointer" ,   color: "rgb(241 154 48)",fontWeight:"600"}}  
                        onClick={() => {
                          this.setState({
                            SalesOrder: "",
                            SalesOrderId: "",
                            selectedSOItems: [],
                            submittedItemData: {},
                          });
                        }}>Clear SO</span>}
                        InputLabelProps={{}}>
                        {SalesOrderList &&
                          SalesOrderList.length > 0 &&
                          SalesOrderList.sort((a, b) => b.id - a.id).map(
                            (option) => (
                              <MenuItem
                                key={option.so_number}
                                value={option.so_number}
                                id={option.id}>
                                {option.so_number}
                              </MenuItem>
                            )
                          )}
                      </CssNewTextField>
                    </div>
                    {/* {editDialog !== false && (
                      <div
                        style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                        {labelText("Last Survey")}
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}>
                          <CssKeyboardDatePicker
                            variant="inline"
                            style={
                              { width: "100%" } //disabled={tp}
                            }
                            onOpen={() => {
                              this.setState({ calenderOpenLS: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpenLS: false });
                            }}
                            maxDate={new Date()}
                            disableFuture
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpenLS ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            // label={"Last Survey"}
                            format="do MMM, yyyy"
                            value={last_survey ? last_survey : new Date()}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ last_survey: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )} */}
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignitems: "center",
                      margin: "-15px",
                      padding: "0px",
                    }}>
                    <CssButton
                      style={{
                        // background: "rgb(241 154 48)",
                        color: "rgb(241 154 48)",
                        marginRight: "10px",
                        marginTop: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        this.setState({
                          SalesOrder: "",
                          SalesOrderId: "",
                          selectedSOItems: [],
                          submittedItemData: {},
                        });
                      }}>
                      clear SO
                    </CssButton>
                  </div> */}
                   {/* <div
                    style={
                      {
                        display: "flex",
                        fontSize: "1rem",
                        color: "grey",
                        background: "white",
                       // marginTop: "20px",
                        minHeight: "71.91px",
                      } //height: "100%",
                    }>  */}
                   
                       
                     {editDialog !== false && (
                      <div
                        style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                        {labelText("Last Survey")}
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}>
                          <CssKeyboardDatePicker
                            variant="inline"
                            style={
                              { width: "100%" } //disabled={tp}
                            }
                            onOpen={() => {
                              this.setState({ calenderOpenLS: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpenLS: false });
                            }}
                            maxDate={new Date()}
                            disableFuture
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpenLS ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            // label={"Last Survey"}
                            format="do MMM, yyyy"
                            value={last_survey ? last_survey : new Date()}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ last_survey: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  {/* </div>  */}
                  {SalesOrder ? (
                    <div className="width-100" id="selectSOItem">
                      <TableComponent
                        // singleUnit={selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.unit}
                        // weight={selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.value}
                        // changeUnit={(value, unit) => {
                        //   return changeUnit(unit, selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.unit, value);
                        // }}
                        bottomBarheader={["Shipped Bags", "Shipped Quantity"]}
                        nobottomBarAction={true}
                        bottomBar={true}
                        setSelectedListArray={(list) => {
                          this.setState({ selectedSOItems: list });
                        }}
                        selectedListArray={selectedSOItems}
                        setSelectedListArrayErrorSet={() => {
                          this.setState({
                            errorMsg: { ...errorMsg, selectedSOItems: false },
                          });
                        }}
                        setSelectedListArrayError={
                          errorMsg["selectedSOItems"]
                            ? errorMsg["selectedSOItems"]
                            : false
                        }
                        checkbox={true}
                        // loading={loading}
                        heightNew="500px"
                        maxHeight="calc(100vh - 40px)"
                        widthNew="900px"
                        module={"Bag details"}
                        actions={[]}
                        noAddButton={true}
                        header={moveToShipHeader2}
                        noSearchBar={true}
                        {...this.props}
                        setSelectedList={(data) => {}}
                        {...this.props}
                        tableList={
                          soitemsList && soitemsList.length > 0
                            ? soitemsList.map((d) => {
                                return {
                                  ...d,
                                  Brand: d.brand_details.brand_name,

                                  "Item Category": ItemCateoryList.filter(
                                    (a) =>
                                      a.id === d.brand_details.item_category
                                  )
                                    ? ItemCateoryList.filter(
                                        (a) =>
                                          a.id === d.brand_details.item_category
                                      )[0].name
                                    : "",

                                  "Item Type": ItemList.filter(
                                    (a) => a.id === d.brand_details.item_type
                                  )
                                    ? `${
                                        ItemList.filter(
                                          (a) =>
                                            a.id === d.brand_details.item_type
                                        )[0].broken_percentage
                                      }%`
                                    : "",

                                  Packaging:
                                    d.packaging &&
                                    d.packaging.map((sP) => {
                                      const newAP = filterData(
                                        PackagingList,
                                        "id",
                                        Number(sP),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PackagingList,
                                            "id",
                                            Number(sP),
                                            "onlyOne"
                                          )
                                        : {};
                                      return `${newAP.size}${newAP.unit}`;
                                    }) &&
                                    d.packaging.map((sP) => {
                                      const newAP = filterData(
                                        PackagingList,
                                        "id",
                                        Number(sP),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PackagingList,
                                            "id",
                                            Number(sP),
                                            "onlyOne"
                                          )
                                        : {};
                                      return `${newAP.size}${newAP.unit}`;
                                    }).length > 0 &&
                                    `tota( )...${
                                      d.packaging.map((sP) => {
                                        const newAP = filterData(
                                          PackagingList,
                                          "id",
                                          Number(sP),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              PackagingList,
                                              "id",
                                              Number(sP),
                                              "onlyOne"
                                            )
                                          : {};
                                        return `${newAP.size}${newAP.unit}`;
                                      })[0]
                                    }`,

                                  ["PackagingUI"]: (
                                    <ShowPackaging
                                      noTotalWeight={true}
                                      noTotalbags={true}
                                      noRemainingBags={true}
                                      details={d.packaging.map((sP) => {
                                        const newAP = filterData(
                                          PackagingList,
                                          "id",
                                          Number(sP),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              PackagingList,
                                              "id",
                                              Number(sP),
                                              "onlyOne"
                                            )
                                          : {};

                                        // newAP.used_bags =
                                        //   sP.total_bags_dispatched;

                                        return newAP;
                                      })}
                                    />
                                  ),

                                  ["HSN Code"]: filterData(
                                    HSNlist,
                                    "id",
                                    d.hsn_code,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        HSNlist,
                                        "id",
                                        d.hsn_code,
                                        "onlyOne"
                                      )["hsn_code"]
                                    : "",
                                  ["Total Item Quantity"]:
                                    d.total_quantity ?? "0",

                                  "Total Item Quantity": submittedItemData[
                                    `${d.id}`
                                  ]
                                    ? submittedItemData[`${d.id}`]
                                    : 0,
                                  "Total Item QuantityUI": () => {
                                    return (
                                      <CssTextField95
                                        required={true}
                                        onInput={(e) => {
                                          if (
                                            Number(e.target.value) >
                                            (d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0)
                                          ) {
                                            this.setState(
                                              {
                                                error: `Only ${
                                                  d.total_quantity ?? 0
                                                } is available in SO Item.`,
                                                submittedItemData: {
                                                  ...submittedItemData,
                                                  [`${d.id}`]:
                                                    d.total_quantity &&
                                                    d.total_quantity !== null
                                                      ? Number(d.total_quantity)
                                                      : 0,
                                                },
                                              },
                                              () => {}
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            max: d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0,
                                          },

                                          endAdornment: Unit,
                                        }}
                                        type="number"
                                        onFocus={StopScroll}
                                        autoComplete="off"
                                        id={`so${d.id}`}
                                        style={{
                                          width: "100%",
                                          margin: "10px 0",
                                        }}
                                        error={
                                          errorMsg[`so${d.id}`]
                                            ? errorMsg[`so${d.id}`]
                                            : false
                                        }
                                        variant="outlined"
                                        value={
                                          submittedItemData[`${d.id}`]
                                            ? submittedItemData[`${d.id}`]
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (
                                            Number(e.target.value) >
                                            (d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0)
                                          ) {
                                            this.setState({
                                              submittedItemData: {
                                                ...submittedItemData,
                                                [`${d.id}`]: d.total_quantity
                                                  ? Number(d.total_quantity)
                                                  : 0,
                                              },
                                              errorMsg: {
                                                ...errorMsg,
                                                [`so${d.id}`]: false,
                                              },
                                            });
                                          } else {
                                            this.setState({
                                              submittedItemData: {
                                                ...submittedItemData,
                                                [`${d.id}`]: e.target.value,
                                              },
                                              errorMsg: {
                                                ...errorMsg,
                                                [`so${d.id}`]: false,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    );
                                  },

                                  ["Contract Rate"]: d.contract_rate
                                    ? `₹ ${Number(d.contract_rate).toFixed(
                                        2
                                      )} /${Unit}`
                                    : "",
                                  ["Buyer Marking"]: d.buyers_marking,

                                  ["Specifications"]:
                                    "abcdweeeeeeeeeeeeeeeeeeeeee",
                                  ["SpecificationsUI"]: (
                                    <ShowSpecifications
                                      Unit={Unit}
                                      details={
                                        d.so_specifications &&
                                        d.so_specifications.length > 0
                                          ? d.so_specifications.map((sP) => {
                                              // sP.used_bags =0
                                              return sP;
                                            })
                                          : []
                                      }
                                    />
                                  ),
                                };
                              })
                            : []
                          // soitemsList&& soitemsList.length>0?soitemsList:""
                        }
                        filterChild={false}
                      />
                    </div>
                  ) : (
                    <>
                      <HeadingLine
                        maxWidth="calc(100% - 20px)"
                        width="calc(100% - 20px)"
                        children={this.props.onlyCreate?"Add PO Item":`Add Multiple PO Items`}
                        center={true}
                        margin={"10px 10px 5px 10px"}
                      />
                      <div
                        style={{
                          border: "1px solid rgba(128, 128, 128, 0.64)",
                        }}
                        className="borderRadius7">
                        {POITemTable(
                          "edit",
                          poItemsArray,
                          null,
                          true,
                          null,
                          null,
                          null,
                          null,
                          editFunct,
                          Unit,
                          true
                        )}

                        {/* started */}
                        {/* {brand -- item category details } */}

                        {this.props.onlyCreate&&poItemsArray.length>0&&!editDialog?""
                        :editDialog && !ItemCategoryId ? (
                          ""
                        ) :poItemsArray.length>0?"": (
                          <div>
                            <div
                              style={{
                                margin: "20px 20px 0px 20px",
                                padding: "10px",
                              }}>
                              {" "}
                              {this.state.defaultStep === 0 ? (
                                <div>
                                  <div
                                    style={
                                      {
                                        display: "flex",
                                        justifyContent: "space-between",

                                        fontSize: "1rem",
                                        color: "grey",
                                        background: "white",
                                        marginBottom: "20px",
                                        width: "100%",
                                        minHeight: "71.91px",
                                      } //height: "100%",
                                    }>
                                    {/* 
<CssTextField
                                   
                                    type="number"
                                    autoComplete="off"
                                    onKeyDown={(evt) => {
                                      // if (singleInput.only_number === true) {
                                      //   evt.key === "." && evt.preventDefault();
                                      // }
                                      evt.key === "e" && evt.preventDefault();

                                      evt.key === "E" && evt.preventDefault();
                                      evt.key === "-" && evt.preventDefault();
                                      evt.keyCode === 38 &&
                                        evt.preventDefault();
                                      evt.keyCode === 40 &&
                                        evt.preventDefault();
                                    }}
                                    InputProps={{
                                      endAdornment: Unit,
                                    }}
                                    //style={styles.withDivider50}
                                    error={
                                      errorMsg.total_quantity ? true : false
                                    }
                                    helperText={
                                      errorMsg.total_quantity
                                        ? errorMsg.total_quantity
                                        : false
                                    }
                                    id="outlined-basic"
                                    label="Brand"
                                    onFocus={StopScroll}
                                 
                                    id="total-quantity"
                                    style={
                                      {
                                        width: "calc(25% - 20px)",
                                        margin: "10px",
                                      } //disabled={tp}
                                    }
                                    variant="outlined"
                                    name="total_quantity"
                                    value={total_quantity}
                                    onChange={this.handleChange}
                                  /> */}





                                    {/* <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Brand")}
                                      <CssTextField
                                        required={false}
                                        id="brand"
                                        autoComplete="off"
                                        value={BrandName ? BrandName : ""}
                                        error={
                                          errorMsg.BrandName ? true : false
                                        }
                                        helperText={
                                          errorMsg.BrandName
                                            ? errorMsg.BrandName
                                            : ""
                                        }
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.BrandName = false;

                                          this.setState({
                                            errorMsg,
                                            BrandNameId: e.target.value,
                                            BrandName: e.target.value,
                                          });
                                        }}
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        // label="Brand"
                                        variant="outlined"
                                        InputLabelProps={{}}
                                      />
                                    </div> */}
                                    {/* {console.log(masters.BrandList,"kkkkkkkkkkkkkkkk")} */}


<div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Brand",true)}
                                      <MultipleSelectBar
                                        // required={false}
                                        // width={"calc(25% - 20px)"}
                                        noLabel={true}
                                        width={"100%"}
                                        // title="brand_name"
                                        // margin={"10px"}
                                        id={"brand"}
                                        // value={brand}
                                        array={
                                          BrandList &&
                                          BrandList.length > 0
                                            ? BrandList.filter((s)=>s.brand_name)
                                            : []
                                        }
                                        value={BrandName&&BrandName!==false?BrandName:[]}
                                        error={
                                          errorMsg.BrandName
                                            ? errorMsg.BrandName
                                            : false
                                        }
                                        helperText={
                                          errorMsg.BrandName
                                            ? errorMsg.BrandName
                                            : ""
                                        }
                                        title="brand_name"
                                        selectedArr={(val) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.BrandName = false;
console.log(val,"BrandList")
                                          this.setState({
                                            errorMsg,
                                            BrandName: val,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Item Category")}
                                      <CssTextField
                                        required={true}
                                        autoComplete="off"
                                        value={
                                          ItemCategory ? ItemCategory : "" //
                                        }
                                        error={
                                          errorMsg.ItemCategory ? true : false
                                        }
                                        helperText={
                                          errorMsg.ItemCategory
                                            ? errorMsg.ItemCategory
                                            : ""
                                        }
                                        select
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.ItemCategory = false;

                                          this.setState({
                                            errorMsg,
                                            ItemCategoryId: e.currentTarget.id,
                                            ItemCategory: e.target.value,
                                          });
                                        }}
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        // label="Item Category"
                                        variant="outlined"
                                        disabled={
                                          this.props.onlyCreate ? true : false
                                        }
                                        InputLabelProps={{}}>
                                        {masters["ItemCateoryList"] &&
                                          masters["ItemCateoryList"].length >
                                            0 &&
                                          masters["ItemCateoryList"].map(
                                            (option) => (
                                              <MenuItem
                                                key={option.name.toString()}
                                                value={option.name}
                                                id={option.id}>
                                                {option.name}
                                              </MenuItem>
                                            )
                                          )}
                                      </CssTextField>
                                    </div>
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Item Type")}
                                      <CssTextField
                                        required={true}
                                        autoComplete="off"
                                        value={
                                          ItemType ? ItemType : "" //
                                        }
                                        error={errorMsg.ItemType ? true : false}
                                        helperText={
                                          errorMsg.ItemType
                                            ? errorMsg.ItemType
                                            : ""
                                        }
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.ItemType = false;

                                          this.setState({
                                            errorMsg,
                                            itemsId: e.currentTarget.id,
                                            ItemType: e.target.value,
                                          });
                                        }}
                                        select
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        // label="Item Type"
                                        variant="outlined"
                                        disabled={
                                          this.props.onlyCreate ? true : false
                                        }>
                                        {masters["ItemList"] &&
                                          masters["ItemList"].length > 0 &&
                                          masters["ItemList"].map((option) => (
                                            <MenuItem
                                              key={option.broken_percentage.toString()}
                                              value={`${option.broken_percentage.toString()} %`}
                                              id={option.id}>
                                              {`${option.broken_percentage.toString()} %`}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                    </div>
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Packaging")}
                                      <MultipleSelectBar
                                        required={true}
                                        // width={"calc(25% - 20px)"}
                                        noLabel={true}
                                        width={"100%"}
                                        // margin={"10px"}
                                        id={"packaging"}
                                        value={packagingArr}
                                        array={
                                          PackagingList &&
                                          PackagingList.length > 0
                                            ? PackagingList.map((sP) => {
                                                sP.sizeUnit = `${sP.size}${sP.unit} - ${sP.bag_type}`;
                                                return sP;
                                              })
                                            : []
                                        }
                                        error={
                                          errorMsg.packagingArr
                                            ? errorMsg.packagingArr
                                            : false
                                        }
                                        helperText={
                                          errorMsg.packagingArr
                                            ? errorMsg.packagingArr
                                            : ""
                                        }
                                        title="sizeUnit"
                                        selectedArr={(val) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.packagingArr = false;

                                          this.setState({
                                            errorMsg,
                                            packagingArr: val,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={
                                      {
                                        display: "flex",
                                        // justifyContent: 'space-between',

                                        fontSize: "1rem",
                                        color: "grey",
                                        background: "white",
                                        margin: "20px 0",
                                        width: "100%",
                                        minHeight: "71.91px",
                                      } //height: "100%",
                                    }>
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("HSN Code")}
                                      <CssTextField
                                        required={true}
                                        autoComplete="off"
                                        value={
                                          HSN ? HSN : "" //
                                        }
                                        id="hsn"
                                        error={errorMsg.HSN ? true : false}
                                        helperText={
                                          errorMsg.HSN ? errorMsg.HSN : ""
                                        }
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.HSN = false;
                                          this.setState({
                                            errorMsg,
                                            HSN: e.target.value,
                                            HSN_id: e.currentTarget.id,
                                          });
                                        }}
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        select
                                        //  label="HSN Code"
                                        variant="outlined"
                                        InputLabelProps={{}}>
                                        {HSNlist &&
                                          HSNlist.length > 0 &&
                                          HSNlist.map((option) => (
                                            <MenuItem
                                              key={option.hsn_code.toString()}
                                              value={option.hsn_code}
                                              id={option.id}>
                                              {option.hsn_code}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                    </div>{" "}
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Total Item Quantity")}
                                      <CssTextField
                                        required={true}
                                        type="number"
                                        autoComplete="off"
                                        onKeyDown={(evt) => {
                                          // if (singleInput.only_number === true) {
                                          //   evt.key === "." && evt.preventDefault();
                                          // }
                                          evt.key === "e" &&
                                            evt.preventDefault();

                                          evt.key === "E" &&
                                            evt.preventDefault();
                                          evt.key === "-" &&
                                            evt.preventDefault();
                                          evt.keyCode === 38 &&
                                            evt.preventDefault();
                                          evt.keyCode === 40 &&
                                            evt.preventDefault();
                                        }}
                                        InputProps={{
                                          endAdornment: Unit,
                                        }}
                                        //style={styles.withDivider50}
                                        error={
                                          errorMsg.total_quantity ? true : false
                                        }
                                        helperText={
                                          errorMsg.total_quantity
                                            ? errorMsg.total_quantity
                                            : false
                                        }
                                        id="outlined-basic"
                                        // label="Total Item Quantity"
                                        onFocus={StopScroll}
                                        // onKeyDown={(evt) => {
                                        //   evt.key === "e" && evt.preventDefault();

                                        //   evt.key === "E" && evt.preventDefault();
                                        //   evt.key === "-" && evt.preventDefault();
                                        // }}
                                        id="total-quantity"
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        name="total_quantity"
                                        value={total_quantity}
                                        onChange={this.handleChange}
                                        disabled={
                                          this.props.onlyCreate ? true : false
                                        }
                                      />
                                    </div>{" "}
                                    <div
                                      style={{
                                        width: "calc(25% - 20px)",
                                        margin: "10px 10px 0px 10px",
                                      }}>
                                      {labelText("Contract Rate")}
                                      <CssTextField
                                        required={true}
                                        type="number"
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                          },
                                        }}
                                        onKeyDown={(evt) => {
                                          // if (singleInput.only_number === true) {
                                          //   evt.key === "." && evt.preventDefault();
                                          // }
                                          evt.key === "e" &&
                                            evt.preventDefault();

                                          evt.key === "E" &&
                                            evt.preventDefault();
                                          evt.key === "-" &&
                                            evt.preventDefault();
                                          evt.keyCode === 38 &&
                                            evt.preventDefault();
                                          evt.keyCode === 40 &&
                                            evt.preventDefault();
                                        }}
                                        autoComplete="off"
                                        // style={
                                        //   {
                                        //     width: "calc(25% - 20px)",
                                        //     margin: "10px",
                                        //   } //disabled={tp}
                                        // }
                                        style={{ width: "100%" }}
                                        InputProps={{
                                          endAdornment: `/${Unit}`,
                                        }}
                                        // InputProps={{
                                        //   startAdornment: <InputAdornment position="start">{Unit}</InputAdornment>,
                                        // }}
                                        error={errorMsg.Rate ? true : false}
                                        helperText={
                                          errorMsg.Rate
                                            ? "Please Add Rate!"
                                            : false
                                        }
                                        id="outlined-basic"
                                        // label="Contract Rate"
                                        onFocus={StopScroll}
                                        variant="outlined"
                                        name="Rate"
                                        id="rate"
                                        value={Rate}
                                        disabled={
                                          this.props.onlyCreate ? true : false
                                        }
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    {this.state.defaultStep === 1 ? (
                                      ""
                                    ) : (
                                      <div
                                        style={{
                                          width: "calc(25% - 20px)",
                                          margin: "10px",
                                        }}
                                        className="d-flex justifyFE"
                                        id="update-button">
                                        <StyledButton
                                          name="Next"
                                          background={
                                            errorMsg["updatedButton"]
                                              ? red
                                              : primary
                                          }
                                          onClick={() => {
                                            if (ItemCategory) {
                                              if (ItemType) {
                                                if (packagingArr.length > 0) {
                                                  if (HSN) {
                                                    if (total_quantity) {
                                                      if (Rate) {
                                                        this.setState({
                                                          defaultStep: 1,
                                                          currentStep: 1,
                                                          errorMsg: {},
                                                        });
                                                      } else {
                                                        const errorMsg =
                                                          this.state.errorMsg;
                                                        errorMsg.Rate =
                                                          "Please give an Contract rate!";

                                                        this.setState({
                                                          errorMsg,
                                                        });
                                                      }
                                                    } else {
                                                      const errorMsg =
                                                        this.state.errorMsg;
                                                      errorMsg.total_quantity =
                                                        "Please give an total_quantity!";

                                                      this.setState({
                                                        errorMsg,
                                                      });
                                                    }
                                                  } else {
                                                    const errorMsg =
                                                      this.state.errorMsg;
                                                    errorMsg.HSN =
                                                      "Please give an HSN!";

                                                    this.setState({
                                                      errorMsg,
                                                    });
                                                  }
                                                } else {
                                                  const errorMsg =
                                                    this.state.errorMsg;
                                                  errorMsg.packagingArr =
                                                    "Please give packaging!";
                                                  this.setState({
                                                    errorMsg,
                                                  });
                                                }
                                              } else {
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                errorMsg.ItemType =
                                                  "Please select an ItemType!";
                                                this.setState({ errorMsg });
                                              }
                                            } else {
                                              const errorMsg =
                                                this.state.errorMsg;
                                              errorMsg.ItemCategory =
                                                "Please select an ItemCategory!";
                                              this.setState({ errorMsg });
                                            }
                                            // } else {
                                            //   const errorMsg =
                                            //     this.state.errorMsg;
                                            //   errorMsg.BrandName =
                                            //     "Please give an BrandName!";
                                            //   this.setState({ errorMsg });
                                            // }
                                          }}
                                          margin="10px 0"
                                          withBg={true}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                SpecificationTable()
                              )}
                              <Stepper activeStep={this.state.defaultStep}>
                                <Step
                                  onClick={() => {
                                    if (this.state.defaultStep === 1) {
                                      this.setState({ defaultStep: 0 });
                                    }
                                  }}
                                  style={{
                                    cursor: this.state.defaultStep
                                      ? "pointer"
                                      : "",
                                  }}>
                                  <StepLabel
                                    style={{
                                      marginLeft: "200px",
                                    }}>
                                    Add Item Details
                                  </StepLabel>
                                </Step>
                                <Step>
                                  <StepLabel
                                    style={{
                                      marginRight: "200px",
                                    }}>
                                    Add Multiple Specifications
                                  </StepLabel>
                                </Step>
                              </Stepper>
                            </div>
                          </div>
                        )}
                        {/* ended */}
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}>
                    <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "10px",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        alignItems: "center",
                        display: "flex",
                      }}>
                      <span style={{ color: "black" }}>Payment Terms : </span>
                      <span style={{ margin: "0 10px" }}>
                        Payment shall be made by RTGS within
                      </span>{" "}
                      <div style={{ width: "calc(15% - 5px)" }}>
                        {labelText("Working Day")}
                        <CssCreateTextField
                          required={true}
                          autoComplete="off"
                          value={
                            PaymentTerm ? PaymentTerm : "" //
                          }
                          id="payment-term"
                          error={errorMsg.PaymentTerm ? true : false}
                          helperText={
                            errorMsg.PaymentTerm ? errorMsg.PaymentTerm : ""
                          }
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.PaymentTerm = false;

                            this.setState({
                              errorMsg,
                              PaymentTermId: filterData(
                                PaymentTermList,
                                "days_range",
                                e.target.value,
                                "onlyOne"
                              )
                                ? filterData(
                                    PaymentTermList,
                                    "days_range",
                                    e.target.value,
                                    "onlyOne"
                                  ).id
                                : "",
                              PaymentTerm: e.target.value,
                            });
                          }}
                          style={
                            { width: "100%" } //disabled={tp}
                          }
                          select
                          //  label="Working Day"
                          variant="outlined">
                          {PaymentTermList &&
                            PaymentTermList.length > 0 &&
                            PaymentTermList.map((option) => (
                              <MenuItem
                                key={option.days_range.toString()}
                                value={option.days_range}
                                id={option.id}>
                                {option.days_range}
                              </MenuItem>
                            ))}
                        </CssCreateTextField>
                      </div>
                      {/* )} */}
                      <span style={{ margin: "0 10px" }}>
                        WD after receipt of material in our CHA godown /CFS.
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                    }}>
                    <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "15px",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        margin: "0 10px",
                      }}>
                      <span style={{ color: "black" }}>Delivery Terms : </span>
                      <span style={{ margin: "0 10px" }}>
                        {contractTypeName} Delivered in buyer’s nominated CHA’s
                        warehouse in{" "}
                        <span
                          style={{
                            margin: "0 5px",
                            textTransform: "capitalize",
                            fontWeight: "600",
                          }}>
                          {portArry &&
                            portArry.map((port, index) => (
                              <span
                                key={port.name.toString()}
                                style={{
                                  margin: "0 10px",
                                  textTransform: "capitalize",
                                  fontWeight: "600",
                                }}>
                                {" "}
                                {`${port.name} - ${port.city}`}
                                {index === portArry.length - 1 ? "" : ","}
                              </span>
                            ))}
                        </span>{" "}
                        by trucks.
                      </span>{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                    }}>
                    <div style={{ width: "100%", margin: "10px" }}>
                      {labelText("Remark",true)}
                      <CssTextField
                        autoComplete="off"
                        style={
                          { width: "100%" } //disabled={tp}
                        }
                        // error={errorMsg.Remark ? true : false}
                        // helperText={
                        //   errorMsg.Remark ? "Please Add Remark!" : false
                        // }
                        id="outlined-basic"
                        // label="Remarks"
                        variant="outlined"
                        name="Remark"
                        value={Remark}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}>
              {" "}
              {added ? (
                editDialog !== false ? (
                  <StyledButton
                    onClick={() => {
                      this.reset("no snac");
                      this.handleClearFields();
                    }}
                    name={"Add Another"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                ) : (
                  ""
                )
              ) : (
                <>
                  <StyledButton
                    onClick={() => {
                      this.reset();
                    }}
                    name={editDialog !== false ? "Reset to default" : "Reset"}
                    // width="120px"
                    margin="0 10px 0 0"
                    // background="rgb(30 53 89)"
                    // color="white"
                  />
                  <StyledButton
                    onClick={() => {
                      // handleSnack();
                      // if (poNum) {
                        if (Unit) {
                          if (contractTypeName) {
                            if (BorkerName) {
                              if (portArry.length > 0) {
                                if (MillerName) {
                                  if (MillerAddress) {
                                    if (MillerGST) {
                                      if (ContactPerson) {
                                        // if (CashDiscount) {
                                        if (
                                          !SalesOrder ||
                                          (SalesOrder &&
                                            selectedSOItems.length > 0)
                                        ) {
                                          if (
                                            SalesOrder ||
                                            (!SalesOrder &&
                                              poItemsArray.length > 0)
                                          ) {
                                            if (PaymentTerm) {
                                              // if (Remark) {
                                              this.setState({
                                                loadingFun: true,
                                              });

                                              const addData = {
                                                contract_date:
                                                  moment(searchedData).format(
                                                    "YYYY-MM-DD"
                                                  ),
                                                miller: Number(MillerNameId),

                                                status: selctedPOStatus,
                                                broker: BorkerNameId,
                                                payment_terms: PaymentTermId,

                                                cash_discount: CashDiscount
                                                  ? CashDiscountId
                                                  : null,
                                                // : "",
                                                unit: Unit,
                                                sales_order: SalesOrder
                                                  ? SalesOrderId
                                                  : "",
                                                remark: Remark,
                                                po_number: poNum,
                                                contract_type:
                                                  Number(contractTypeId),
                                                ports: portArry.map((sP) => {
                                                  return sP.id;
                                                }),
                                                brokerage: BorkerageId,
                                                last_survey: last_survey
                                                  ? moment(last_survey).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : null,
                                                total_quantity: SalesOrderId
                                                  ? selectedSOItems.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          submittedItemData[
                                                            currentValue
                                                          ]
                                                            ? submittedItemData[
                                                                currentValue
                                                              ]
                                                            : 0
                                                        ),
                                                      0
                                                    )
                                                  : poItemsArray.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          currentValue[
                                                            "Total Item Quantity"
                                                          ]
                                                        ),
                                                      0
                                                    ),
                                                total_po_quantity: SalesOrderId
                                                  ? selectedSOItems.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          submittedItemData[
                                                            currentValue
                                                          ]
                                                            ? submittedItemData[
                                                                currentValue
                                                              ]
                                                            : 0
                                                        ),
                                                      0
                                                    )
                                                  : poItemsArray.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          currentValue[
                                                            "Total Item Quantity"
                                                          ]
                                                        ),
                                                      0
                                                    ),
                                              };
                                              const soErrors = [];
                                              if (
                                                SalesOrder &&
                                                selectedSOItems.length > 0
                                              ) {
                                                selectedSOItems.map(
                                                  (singleItem) => {
                                                    if (
                                                      submittedItemData[
                                                        singleItem
                                                      ] &&
                                                      Number(
                                                        submittedItemData[
                                                          singleItem
                                                        ]
                                                      ) !== 0
                                                    ) {
                                                    } else {
                                                      if (
                                                        !soErrors.includes(
                                                          singleItem
                                                        )
                                                      )
                                                        soErrors.push(
                                                          singleItem
                                                        );
                                                    }
                                                  }
                                                );
                                              }

                                              if (editDialog === false) {
                                                addData["is_active"] = true;
                                              }
                                              if (editDialog === false) {
                                                addData[
                                                  "dispatched_quantity"
                                                ] = 0;
                                                addData["remaining_quantity"] =
                                                  SalesOrderId
                                                    ? selectedSOItems.reduce(
                                                        (
                                                          previousValue,
                                                          currentValue
                                                        ) =>
                                                          Number(
                                                            previousValue
                                                          ) +
                                                          Number(
                                                            submittedItemData[
                                                              currentValue
                                                            ]
                                                              ? submittedItemData[
                                                                  currentValue
                                                                ]
                                                              : 0
                                                          ),
                                                        0
                                                      )
                                                    : poItemsArray.reduce(
                                                        (
                                                          previousValue,
                                                          currentValue
                                                        ) =>
                                                          Number(
                                                            previousValue
                                                          ) +
                                                          Number(
                                                            currentValue[
                                                              "Total Item Quantity"
                                                            ]
                                                          ),
                                                        0
                                                      );
                                              }
                                              const addData_PO_ITEM = [];
                                              const oldPoItem = [];
                                              const newPoItem = [];
                                              const newPOAddObj = {};

                                              newPOAddObj["purchase_order"] =
                                                addData;
                                              if (this.props.onlyCreate) {
                                                newPOAddObj.sales_intent_id =
                                                  this.props.onlyCreate.id;
                                              }
                                              if (SalesOrderId) {
                                                if (
                                                  selectedSOItems.length > 0
                                                ) {
                                                  // if (editDialog !== false) {
                                                  //   poItemsArray.map((s) => {
                                                  //     if (
                                                  //       !selectedSOItems.includes(
                                                  //         s
                                                  //       )
                                                  //     ) {
                                                  //       addData_PO_ITEM.push({
                                                  //         id: s.id,
                                                  //       });
                                                  //     }
                                                  //     console.log(
                                                  //       selectedSOItems,
                                                  //       "selectedSOItems"
                                                  //     );
                                                  //     return s;
                                                  //   });
                                                  // }
                                                  var doMap = true;
                                                  selectedSOItems.map(
                                                    (single) => {
                                                      const filterSoitem =
                                                        filterData(
                                                          soitemsList,
                                                          "id",
                                                          Number(single),
                                                          "onlyOne"
                                                        )
                                                          ? filterData(
                                                              soitemsList,
                                                              "id",
                                                              Number(single),
                                                              "onlyOne"
                                                            )
                                                          : undefined;

                                                      if (
                                                        doMap === true &&
                                                        filterSoitem &&
                                                        filterSoitem !==
                                                          undefined
                                                      ) {
                                                        const poData =
                                                          filterData(
                                                            poItemsArray,
                                                            "soitem_id",
                                                            Number(single),
                                                            "onlyOne"
                                                          )
                                                            ? filterData(
                                                                poItemsArray,
                                                                "soitem_id",
                                                                Number(single),
                                                                "onlyOne"
                                                              )
                                                            : undefined;
                                                        const newData = {};
                                                        newData[
                                                          "item_category"
                                                        ] =
                                                          filterSoitem.brand_details.item_category;
                                                        newData["item_type"] =
                                                          filterSoitem.brand_details.item_type;
                                                        newData["brand"] =
                                                          filterSoitem.brand;
                                                        newData[
                                                          "contract_rate"
                                                        ] =
                                                          filterSoitem.contract_rate;
                                                        const specificationsArray =
                                                          poData
                                                            ? poData.specifications
                                                            : filterSoitem.so_specifications;

                                                        if (
                                                          specificationsArray.length >
                                                          0
                                                        ) {
                                                          newData[
                                                            "specifications"
                                                          ] =
                                                            specificationsArray.map(
                                                              (s) => {
                                                                delete s.created_at;

                                                                delete s.created_by;

                                                                delete s.modified_by;

                                                                delete s.modified_at;

                                                                return s;
                                                              }
                                                            );
                                                        }
                                                        // newData["specifications"] =
                                                        //   filterSoitem.so_specifications;
                                                        newData["hsn_code"] =
                                                          filterSoitem.hsn_code;
                                                        newData["packaging"] =
                                                          filterSoitem.packaging;

                                                        // if (
                                                        //   editDialog === false
                                                        // ) {
                                                        newData[
                                                          "po_packaging"
                                                        ] =
                                                          filterSoitem.packaging.map(
                                                            (sP) => {
                                                              return {
                                                                packaging: sP,
                                                              };
                                                            }
                                                          );
                                                        // }
                                                        newData[
                                                          "total_quantity"
                                                        ] =
                                                          submittedItemData[
                                                            single
                                                          ];
                                                        newData["soitem_id"] =
                                                          single;

                                                        if (
                                                          editDialog !==
                                                            false &&
                                                          poData
                                                        ) {
                                                          newData["id"] =
                                                            poData.id;

                                                          // newData["po_items"] =
                                                          //   poData.id;
                                                          newData[
                                                            "remaining_bags"
                                                          ] =
                                                            poData.remaining_bags
                                                              ? poData.remaining_bags
                                                              : 0;
                                                          newData["used_bags"] =
                                                            poData.used_bags
                                                              ? poData.used_bags
                                                              : 0;
                                                          newData[
                                                            "dispatched_quantity"
                                                          ] =
                                                            poData.dispatched_quantity
                                                              ? poData.dispatched_quantity
                                                              : 0;

                                                          newData[
                                                            "purchase_order"
                                                          ] = editDialog.id;
                                                        } else if (editDialog) {
                                                          newData[
                                                            "purchase_order"
                                                          ] = editDialog.id;
                                                        }
                                                        addData_PO_ITEM.push(
                                                          newData
                                                        );
                                                      } else {
                                                        doMap = false;
                                                        scrollDiv(
                                                          "selectSOItem"
                                                        );
                                                        const errorMsg =
                                                          this.state.errorMsg;
                                                        errorMsg.selectedSOItems =
                                                          "Please select so items!";

                                                        this.setState({
                                                          errorMsg,
                                                        });
                                                      }

                                                      return single;
                                                    }
                                                  );
                                                } else {
                                                  // const errorMsg =
                                                  //   this.state.errorMsg;
                                                  // scrollDiv(`so${soErrors[0]}`);
                                                  // soErrors.map((s) => {
                                                  //   errorMsg[`so${s}`] = true;
                                                  // });
                                                  // console.log(
                                                  //   errorMsg,
                                                  //   "errorMsg"
                                                  // );
                                                  // // errorMsg.SalesOrder = "SO Quantity is less!";
                                                  // this.setState({
                                                  //   loadingFun: false,
                                                  //   errorMsg,
                                                  // });
                                                  scrollDiv("selectSOItem");
                                                  const errorMsg =
                                                    this.state.errorMsg;
                                                  errorMsg.selectedSOItems =
                                                    "Please select so items!";

                                                  this.setState({ errorMsg });
                                                }
                                              } else {
                                                poItemsArray.map((ss) => {
                                                  const newData = {};
                                                  newData["item_category"] =
                                                    ss["Item Categoryid"];
                                                  if (
                                                    SalesOrderId &&
                                                    ss.soitem_id
                                                  ) {
                                                    newData["soitem_id"] =
                                                      ss.soitem_id;
                                                  }
                                                  newData["item_type"] =
                                                    ss["Item Typeid"];
                                                    console.log(ss,"ssssssss")
                                                  newData["brand"] =
                                                    ss["Brandid"] &&
                                                    ss["Brandid"] !== false
                                                      ? ss["Brandid"].length>0?
                                                      ss["Brandid"].map((data)=>(typeof data==="object"&&data.brand_name)?data.brand_name:data)
                                                      :ss["Brandid"]
                                                      : null;
                                                  newData["contract_rate"] =
                                                    ss["Contract Rate"];
                                                  if (
                                                    ss.specificationArr &&
                                                    ss.specificationArr.length >
                                                      0
                                                  ) {
                                                    const new_specifications =
                                                      [];
                                                    const old_specifications =
                                                      [];
                                                    ss.specificationArr.map(
                                                      (spec) => {
                                                        if (spec.created_at) {
                                                          old_specifications.push(
                                                            {
                                                              name: spec.name,
                                                              condition:
                                                                spec.conditionId
                                                                  ? spec.conditionId
                                                                  : spec.condition,
                                                              value: spec.value,
                                                              id: spec.id,
                                                              po_items:
                                                                spec.po_items,
                                                            }
                                                          );
                                                        } else {
                                                          new_specifications.push(
                                                            {
                                                              name: spec.name,
                                                              condition:
                                                                spec.conditionId
                                                                  ? spec.conditionId
                                                                  : spec.condition,
                                                              value: spec.value,
                                                              po_items:
                                                                editDialog !==
                                                                false
                                                                  ? ss.id
                                                                  : "",
                                                            }
                                                          );
                                                        }
                                                        return spec;
                                                      }
                                                    );
                                                    newData[
                                                      "new_specifications"
                                                    ] = new_specifications;
                                                    newData["specifications"] =
                                                      editDialog !== false
                                                        ? old_specifications
                                                        : new_specifications;
                                                  }

                                                  newData["hsn_code"] =
                                                    ss["HSN Codeid"];
                                                  if (editDialog !== false) {
                                                    newData["packaging"] =
                                                      ss.packagingArr &&
                                                      ss.packagingArr.length > 0
                                                        ? ss.packagingArr.map(
                                                            (sp) => {
                                                              return Number(
                                                                sp.id
                                                              );
                                                            }
                                                          )
                                                        : ss.packagingArr &&
                                                          ss.packagingArr
                                                            .length > 0
                                                        ? ss.packagingArr.map(
                                                            (sp) => {
                                                              const newIb = {
                                                                packaging:
                                                                  Number(sp.id),
                                                              };
                                                              return newIb;
                                                            }
                                                          )
                                                        : [];
                                                    // newData["packaging"] =
                                                    //   ss.packaging &&
                                                    //   ss.packaging.length > 0
                                                    //     ? ss.packaging.map((sp) => {
                                                    //         return Number(sp);
                                                    //       })
                                                    //     : ss.packaging &&
                                                    //       ss.packaging.length > 0
                                                    //     ? ss.packaging.map((sp) => {
                                                    //         const newIb = {
                                                    //           packaging: Number(sp),
                                                    //         };
                                                    //         return newIb;
                                                    //       })
                                                    //     : [];
                                                    newData["purchase_order"] =
                                                      editDialog.id;
                                                  } else {
                                                  }
                                                  if (
                                                    editDialog === false ||
                                                    SalesOrderId
                                                  ) {
                                                    newData["po_packaging"] =
                                                      ss.packaging &&
                                                      ss.packaging.length > 0
                                                        ? ss.packaging.map(
                                                            (sp) => {
                                                              const newIb = {
                                                                packaging: sp,
                                                              };
                                                              return newIb;
                                                            }
                                                          )
                                                        : [];
                                                  }
                                                  newData["total_quantity"] =
                                                    ss["Total Item Quantity"];
                                                  if (editDialog !== false) {
                                                    newData["id"] = ss.id;
                                                    newData["remaining_bags"] =
                                                      ss.remaining_bags
                                                        ? ss.remaining_bags
                                                        : 0;
                                                    newData["used_bags"] =
                                                      ss.used_bags
                                                        ? ss.used_bags
                                                        : 0;
                                                    newData[
                                                      "dispatched_quantity"
                                                    ] = ss.dispatched_quantity
                                                      ? ss.dispatched_quantity
                                                      : 0;
                                                  }
                                                  newData["soitem_id"] =
                                                    SalesOrderId
                                                      ? ss.soitem_id
                                                      : null;
                                                  addData_PO_ITEM.push(newData);
                                                  return ss;
                                                });
                                              }

                                              addData_PO_ITEM.map(
                                                (item, index) => {
                                                  if (
                                                    index >= tableLen ||
                                                    !item.id
                                                  ) {
                                                    const obj = item;
                                                    delete obj.id;
                                                    if (
                                                      item.specifications &&
                                                      item.specifications
                                                        .length === 0
                                                    ) {
                                                      delete obj.specifications;

                                                      obj.new_specifications.map(
                                                        (item, index) => {
                                                          delete item.po_items;
                                                        }
                                                      );
                                                    }
                                                    newPoItem.push(obj);
                                                  } else {
                                                    oldPoItem.push(item);
                                                  }
                                                }
                                              );

                                              //  newPoItem && newPoItem.length>0? (
                                              //    newPoItem.map((item,index)=>{

                                              //    })
                                              //  ):""

                                              newPOAddObj["po_items"] =
                                                addData_PO_ITEM;

                                              if (
                                                SalesOrderId &&
                                                soErrors.length > 0
                                                //   (poItemsArray.reduce(
                                                //   (
                                                //     previousValue,
                                                //     currentValue
                                                //   ) =>
                                                //     Number(previousValue) +
                                                //     Number(
                                                //       currentValue[
                                                //         "Total Item Quantity"
                                                //       ]
                                                //     ),
                                                //   0
                                                // )
                                                // >
                                                // (filterData(SalesOrderList,"id",Number(SalesOrderId),"onlyOne")?Number(filterData(SalesOrderList,"id",Number(SalesOrderId),"onlyOne").total_quantity):"")))
                                              ) {
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                scrollDiv(`so${soErrors[0]}`);
                                                soErrors.map((s) => {
                                                  errorMsg[`so${s}`] = true;
                                                });

                                                // errorMsg.SalesOrder = "SO Quantity is less!";
                                                this.setState({
                                                  loadingFun: false,
                                                  errorMsg,
                                                });
                                              } else {
                                                this.props
                                                  .PurchaseOrdersFunction(
                                                    editDialog !== false
                                                      ? "patch"
                                                      : "post",
                                                    editDialog !== false
                                                      ? "dispatch/purchase-order"
                                                      : "dispatch/purchase-order/create",
                                                    editDialog !== false
                                                      ? editDialog.id
                                                      : null,
                                                    null,
                                                    editDialog !== false
                                                      ? addData
                                                      : newPOAddObj,
                                                    null,
                                                    "noeditparam"
                                                  )
                                                  .then((res) => {
                                                    if (res.error) {
                                                      this.setState({
                                                        error:
                                                          typeof res.data ===
                                                          "string"
                                                            ? res.data
                                                            : res.data.length >
                                                              0
                                                            ? res.data
                                                                .map((s) => {
                                                                  return `${
                                                                    s.field
                                                                      ? `${s.field} :`
                                                                      : ""
                                                                  } ${
                                                                    typeof s.message ===
                                                                    "string"
                                                                      ? s.message
                                                                      : s
                                                                          .message
                                                                          .length >
                                                                          0 &&
                                                                        s
                                                                          .message[0] &&
                                                                        s
                                                                          .message[0]
                                                                  }`;
                                                                })
                                                                .join(" ,")
                                                            : res.data,
                                                        loadingFun: false,
                                                      });
                                                    } else {
                                                      if (
                                                        editDialog !== false
                                                      ) {
                                                        this.props
                                                          .PurchaseOrdersFunction(
                                                            editDialog !== false
                                                              ? "put"
                                                              : "post",
                                                            editDialog !== false
                                                              ? "dispatch/po-items/bulk-update"
                                                              : "dispatch/po-items/list",
                                                            null,
                                                            null,
                                                            oldPoItem
                                                          )
                                                          .then((res2) => {
                                                            if (res2.error) {
                                                              this.setState({
                                                                error:
                                                                  typeof res2.data ===
                                                                  "string"
                                                                    ? res2.data
                                                                    : res2.data
                                                                        .length >
                                                                      0
                                                                    ? res2.data
                                                                        .map(
                                                                          (
                                                                            s
                                                                          ) => {
                                                                            return `${
                                                                              s.field
                                                                                ? `${s.field} :`
                                                                                : ""
                                                                            } ${
                                                                              typeof s.message ===
                                                                              "string"
                                                                                ? s.message
                                                                                : s
                                                                                    .message
                                                                                    .length >
                                                                                    0 &&
                                                                                  s
                                                                                    .message[0] &&
                                                                                  s
                                                                                    .message[0]
                                                                            }`;
                                                                          }
                                                                        )
                                                                        .join(
                                                                          " ,"
                                                                        )
                                                                    : res2.data,
                                                                loadingFun: false,
                                                              });
                                                            } else {
                                                              if (
                                                                editDialog !==
                                                                  false &&
                                                                newPoItem.length >
                                                                  0
                                                              ) {
                                                                this.props
                                                                  .PurchaseOrdersFunction(
                                                                    "post",
                                                                    "dispatch/po-items/bulk-create",
                                                                    null,
                                                                    null,
                                                                    newPoItem
                                                                  )
                                                                  .then(
                                                                    (res) => {}
                                                                  );
                                                              }
                                                              localStorage.setItem(
                                                                "selected-po",
                                                                singlelistView
                                                              );

                                                              this.props
                                                                .PurchaseOrdersFunction(
                                                                  "get",
                                                                  EndPoints[
                                                                    "PurchaseOrders"
                                                                  ],
                                                                  null,
                                                                  null,
                                                                  null,
                                                                  selctedStatus ===
                                                                    "CLOSED" &&
                                                                    selectedMonth
                                                                    ? {
                                                                        month:
                                                                          selectedMonth.getMonth() +
                                                                          1,
                                                                        status:
                                                                          selctedStatus,
                                                                      }
                                                                    : {
                                                                        status:
                                                                          selctedStatus,
                                                                      }
                                                                )
                                                                .then(() => {
                                                                  if (
                                                                    editDialog !==
                                                                    false
                                                                  ) {
                                                                    this.props.setPoItemsPrev(
                                                                      poItemsArray,
                                                                      poItemsArray,
                                                                      poItemsArray
                                                                    );
                                                                  }
                                                                  this.setState(
                                                                    {
                                                                      added:
                                                                        "Purchase Order Edited!",
                                                                      loadingFun: false,
                                                                      success:
                                                                        "Purchase Order Edited!",
                                                                    },
                                                                    () => {}
                                                                  );
                                                                });
                                                            }
                                                          });
                                                      } else {
                                                        // this.setState({
                                                        //   editSnack: true,
                                                        // });
                                                        localStorage.setItem(
                                                          "selected-po",
                                                          singlelistView
                                                        );
                                                        this.props
                                                          .PurchaseOrdersFunction(
                                                            "get",
                                                            EndPoints[
                                                              "PurchaseOrders"
                                                            ],
                                                            null,
                                                            null,
                                                            null,
                                                            selctedStatus ===
                                                              "CLOSED" &&
                                                              selectedMonth
                                                              ? {
                                                                  month:
                                                                    selectedMonth.getMonth() +
                                                                    1,
                                                                  status:
                                                                    selctedStatus,
                                                                }
                                                              : {
                                                                  status:
                                                                    selctedStatus,
                                                                }
                                                          )
                                                          .then(() => {
                                                            this.setState({
                                                              added:
                                                                "Purchase Order Added!",
                                                              loadingFun: false,
                                                              success:
                                                                "Purchase Order Added!",
                                                            });
                                                          });
                                                      }
                                                    }
                                                  });
                                              }
                                            } else {
                                              const errorMsg =
                                                this.state.errorMsg;

                                              scrollDiv("payment-term");
                                              errorMsg.PaymentTerm =
                                                "Please give an PaymentTerm!";
                                              this.setState({
                                                errorMsg,
                                              });
                                            }
                                          } else {
                                            scrollDiv("update-button");
                                            const errorMsg =
                                              this.state.errorMsg;
                                            // errorMsg.ItemCategory =
                                            //   "Please select an ItemCategory!";
                                            // errorMsg.ItemType =
                                            //   "Please select an ItemType!";
                                            // errorMsg.BrandName =
                                            //   "Please select an Brand!";
                                            // errorMsg.packagingArr =
                                            //   "Please select an Packaging!";
                                            // errorMsg.HSN =
                                            //   "Please select an HSN!";
                                            errorMsg.updatedButton =
                                              "Please add";
                                            this.setState({ errorMsg });
                                          }
                                        } else {
                                          scrollDiv("selectSOItem");
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.selectedSOItems =
                                            "Please select so items!";

                                          this.setState({ errorMsg });
                                        }
                                        // } else {
                                        //   scrollDiv("cash_discount");
                                        //   const errorMsg = this.state.errorMsg;
                                        //   errorMsg.CashDiscount =
                                        //     "Please give cash discount!";

                                        //   this.setState({ errorMsg });
                                        // }
                                      } else {
                                        scrollDiv("contact-person");
                                        const errorMsg = this.state.errorMsg;
                                        errorMsg.ContactPerson =
                                          "Please give an ContactPerson!";

                                        this.setState({ errorMsg });
                                      }
                                    } else {
                                      scrollDiv("miller");
                                      const errorMsg = this.state.errorMsg;
                                      errorMsg.MillerGST =
                                        "Please give an MillerGST!";

                                      this.setState({ errorMsg });
                                    }
                                  } else {
                                    scrollDiv("miller");
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.MillerAddress =
                                      "Please give an MillerAddress!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  const errorMsg = this.state.errorMsg;
                                  scrollDiv("miller");
                                  errorMsg.MillerName =
                                    "Please give an MillerName!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                scrollDiv("port");
                                const errorMsg = this.state.errorMsg;
                                errorMsg.portArry = "Please give Port!";

                                this.setState({ errorMsg });
                              }
                            } else {
                              scrollDiv("broker");
                              const errorMsg = this.state.errorMsg;
                              errorMsg.BorkerName =
                                "Please give an BorkerName!";

                              this.setState({ errorMsg });
                            }
                          } else {
                            // Update;
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("contract-type");
                            errorMsg.contractTypeName =
                              "Please select a Contract Type!";
                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("unit");
                          errorMsg.Unit = "Please select a Unit!";
                          this.setState({ errorMsg });
                        }
                      // } else {
                      //   // scrollDiv("poNum");
                      //   const errorMsg = this.state.errorMsg;
                      //   errorMsg.poNum = "Please give PO Number!";

                      //   this.setState({ errorMsg });
                      // }
                    }}
                    name={editDialog === false ? "Submit" : "Update"}
                    withBg={true}
                    background={"rgb(241 154 48)"}
                    width="120px"
                  />
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {onlyDialog !== true && singlelistView && (
          <>
            {pushNoti === true ? (
              <Dialog
                open={true}
                onClose={() => this.setState({ pushNoti: false })}
                aria-labelledby="confirm-dialog">
                <DialogTitle id="confirm-dialog">
                  Purchase Order cannot be deleted!
                </DialogTitle>
                <DialogContent>
                  Dispatch/Survey Report already exists
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        pushNoti: false,
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "10px",
                    }}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            {confirmDel === true ? (
              <Dialog
                open={true}
                onClose={() => this.setState({ confirmDel: false })}
                aria-labelledby="confirm-dialog">
                <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this PO ?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        confirmDel: false,
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirm}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            {downloadPdf === true ? (
              <PurchaseOrderPdf
                getSpecificationName={this.getSpecificationName}
                tableList={poItemsArrayTOPreview}
                header={[
                  "Serial No",
                  "Description",
                  `Net Qty (${
                    singlelistView && singlelistView.unit
                      ? singlelistView.unit
                      : ""
                  })`,
                  `Unit Price Per ${
                    singlelistView && singlelistView.unit
                      ? singlelistView.unit
                      : ""
                  } (Inclusive of all Taxes)`,
                  // "Sale Tax Terms",
                ]}
                {...this.state}
                {...this.props}
                onClose={() => {
                  this.setState({
                    downloadPdf: false,
                    success:"Purchase Order pdf downloaded"
                  });
                }}
                widthList={["80px", "100px", "80px", "140px", "110px"]}
              />
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                textTransform: "capitalize",
                borderRadius: " 5px",
                marginBottom: "10px",
              }}>
              {widget_permission?.widget?.has_update_permission && (
                <CssButton
                  style={{
                    background: "rgb(241 154 48)",
                    color: "white",
                    marginRight: "10px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    // this.props.openDialogFunct()

                    if (
                      singlelistView &&
                      !singlelistView.total_dispatched_quantity
                    ) {
                      this.setState(
                        {
                          editDialog: singlelistView,
                          loadingFun: true,
                          showSpeci: false,
                        },
                        () => {
                          this.setData(
                            singlelistView && singlelistView.unit
                              ? singlelistView.unit
                              : ""
                          );
                        }
                      );
                    } else {
                      this.setState({
                        error:
                          "Dispatch exists, can't edit this Purchase Order",
                      });
                    }
                  }}>
                  Edit{" "}
                  <ModeOutlinedIcon
                    fontSize="small"
                    style={{ height: "17px", margin: "0 0 3px 0" }}
                  />
                </CssButton>
              )}
              <CssButton
                onClick={() => {
                  this.setState({ downloadPdf: true });
                }}
                style={{
                  background: "rgb(241 154 48)",
                  color: "white",
                  marginRight: "10px",

                  textTransform: "capitalize",
                }}>
                Download <DownloadIcon fontSize="small" />
              </CssButton>
              <CssButton
                onClick={() => {
                  this.setState({ confirmDel: true });
                }}
                style={{
                  background: "rgb(241 154 48)",
                  color: "white",
                  textTransform: "capitalize",
                }}>
                Delete <DeleteIcon fontSize="small" />
              </CssButton>
            </div>
            <div
              className="scrollBluePrimary"
              style={{
                height: "calc(100% - 42.5px)",
                overflow: "auto",
                width: "100%",
              }}>
              <div
                style={{
                  height: "fit-content",
                  width: "fit-content",
                  minWidth: "100%",
                  // margin:"5px 10px"
                }}>
                <div style={styles.innerDiv}>
                  <div style={styles.innerMainDiv}>
                    <Paper
                      style={{
                        width: "calc(100% - 33.33px)",
                        // marginRight: "25px",
                        boxShadow: "1px 2px 6px 1px #d3d3d38f",
                        padding: "15px",
                        background: "rgb(0 0 0 / 1%)",
                        pointerEvents: "none",
                        margin: "5px 25px 5px 5px",
                      }}>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>PO Number</div>
                        <div style={styles.subHeading}>
                          {singlelistView && singlelistView.po_number}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Contract Date</div>
                        <div style={styles.subHeading}>
                          {singlelistView &&
                            moment(singlelistView.contract_date).format(
                              "ddd, MMM Do YYYY"
                            )}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>SO Number</div>
                        <div style={styles.subHeading}>
                          {singlelistView && singlelistView.sales_order_details
                            ? singlelistView.sales_order_details.so_number
                            : " - "}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Last Survey</div>
                        <div style={styles.subHeading}>
                          {singlelistView && singlelistView.last_survey
                            ? moment(
                                singlelistView && singlelistView.last_survey
                              ).format("ddd, MMM Do YYYY")
                            : ""}
                        </div>
                      </div>
                    </Paper>
                    <Paper
                      style={{
                        width: "calc(100% - 33.33px)",
                        // marginRight: "25px",
                        margin: "5px 25px 5px 5px",
                        boxShadow: "1px 2px 6px 1px #d3d3d38f",
                        padding: "15px",
                        background: "rgb(0 0 0 / 1%)",
                        pointerEvents: "none",
                      }}>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Broker Name</div>
                        <div style={styles.subHeading}>
                          {singlelistView && singlelistView.broker_details.name}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Miller Name & Address</div>
                        <div style={styles.subHeading}>{`${
                          singlelistView.miller_details &&
                          singlelistView.miller_details.name
                        }, ${
                          singlelistView &&
                          singlelistView.miller_details.address
                        }`}</div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Miller GST</div>
                        <div style={styles.subHeading}>
                          {singlelistView &&
                            singlelistView.miller_details.gstin}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Contact Person</div>
                        <div style={styles.subHeading}>
                          {singlelistView &&
                            singlelistView.miller_details.contact_person}
                        </div>
                      </div>
                    </Paper>
                    <Paper
                      style={{
                        width: "calc(100% - 33.33px)",
                        // marginRight: "25px",
                        margin: "5px 5px 5px 5px",
                        padding: "15px",
                        boxShadow: "1px 2px 6px 1px #d3d3d38f",
                        background: "rgb(0 0 0 / 1%)",
                        pointerEvents: "none",
                      }}>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Cash Discount</div>
                        {singlelistView &&
                        singlelistView.cash_discount_details ? (
                          <div style={styles.subHeading}>
                            {`${
                              singlelistView &&
                              singlelistView.cash_discount_details?.discount
                            }` +
                              `${"%"}` +
                              `${" "}` +
                              `${"will expire in"}` +
                              `${" "}` +
                              `${
                                singlelistView &&
                                singlelistView.cash_discount_details
                                  ?.expiry_days
                              }` +
                              `${"days"}`}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}> Port Name</div>

                        <div style={styles.subHeading}>
                          {singlelistView &&
                          singlelistView.ports_details &&
                          singlelistView.ports_details.length > 0
                            ? singlelistView.ports_details.map((data) => {
                                return data ? data.city + `${","}` : "";
                              })
                            : []}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Contract Type</div>
                        <div style={styles.subHeading}>
                          {singlelistView &&
                            singlelistView.contract_type_details.contract_type}
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <div style={styles.heading1}>Status</div>
                        <div style={styles.subHeading}>
                          {singlelistView && singlelistView.status}
                        </div>
                      </div>
                    </Paper>
                    {/* <Paper
                      style={{
                        width:"calc(100% - 33.33px)",
                        marginRight: "25px",
                        padding: "15px",
                        background: "rgba(251, 224, 191, 1)",
                        pointerEvents: "none",
                      }}>
                      {" "}
                      <div
                        style={{
                          pointerEvents: "none",
                          marginBottom: "10px",
                        }}>
                        <TextField
                          id="standard-multiline-flexible"
                          variant="standard"
                          style={
                            {
                              width: "270px",
                              color: "black",
                            } //disabled={tp}
                          }
                          // id="outlined-disabled"
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="PO Number"
                          value={singlelistView && singlelistView.po_number}
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Contract Date"
                          value={
                            singlelistView &&
                            moment(singlelistView.contract_date).format(
                              "ddd, MMM Do YYYY"
                            )
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          label="SO Number"
                          value={
                            singlelistView && singlelistView.sales_order_details
                              ? singlelistView.sales_order_details.so_number
                              : ""
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Broker"
                          value={
                            singlelistView && singlelistView.broker_details.name
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Survey Date"
                          // value={moment(
                          //   singlelistView && singlelistView.last_survey
                          // ).format("ddd, MMM Do YYYY")}

                          value={
                            singlelistView && singlelistView.last_survey
                              ? moment(
                                  singlelistView && singlelistView.last_survey
                                ).format("ddd, MMM Do YYYY")
                              : ""
                          }
                        />
                      </div>
                    </Paper> */}
                    {/* <div style={{ width: '33.3%' }}> */}{" "}
                    {/* -------------------------------------- */}
                    {/* <Paper
                      style={{
                        width:"calc(100% - 33.33px)",
                        marginRight: "25px",
                        padding: "15px",
                        background: "rgba(251, 224, 191, 1)",
                      }}>
                      {" "}
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          // maxRows={10}
                          variant="standard"
                          style={{
                            width: "270px",
                            color: "black",
                          }}
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="MillerName & Address"
                          value={`${
                            singlelistView.miller_details &&
                            singlelistView.miller_details.name
                          }, ${
                            singlelistView &&
                            singlelistView.miller_details.address
                          }`}
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={{
                            width: "270px",
                          }}
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Miller GST No."
                          value={
                            singlelistView &&
                            singlelistView.miller_details.gstin
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Contact Person"
                          value={
                            singlelistView &&
                            singlelistView.miller_details.contact_person
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Cash Discount"
                          value={
                            `${singlelistView &&
                            singlelistView.cash_discount_details?.discount}` + `${"%"}` 
                            +  `${" "}` + `${"will expire in"}` +  `${" "}` +
                            `${singlelistView &&
                            singlelistView.cash_discount_details?.expiry_days}` + `${"days"}`
                          }
                        />
                      </div>
                      </Paper> */}
                    {/* ----------- */}
                    {/* <CssValueTextField
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        variant="standard"
                        style={
                          {
                            width: "270px",
                          } //disabled={tp}
                        }
                        onKeyDown={(evt) => evt.preventDefault()}
                        label="Survey Date"
                        // value={moment(
                        //   singlelistView && singlelistView.last_survey
                        // ).format("ddd, MMM Do YYYY")}

                        value={
                          singlelistView && singlelistView.last_survey
                            ? moment(
                                singlelistView && singlelistView.last_survey
                              ).format("ddd, MMM Do YYYY")
                            : ""
                        }
                      /> */}
                    {/* <div style={{ marginBottom: '10px' }}>
                    <MultipleSelectBar
                      width={'210px'}
                      margin={'10px 0'}
                      id="standard-multiline-flexible"
                      multiline
                      maxRows={4}
                      variant="standard"
                      value={
                        singlelistView &&
                        singlelistView.ports_details.length > 0
                          ? singlelistView.ports_details
                          : []
                      }
                      array={
                        singlelistView &&
                        singlelistView.ports_details.length > 0
                          ? singlelistView.ports_details
                          : [] //array pass krre na toh city nhi aaega
                      }
                      disabled={true}
                      title="city"
                    />
                  </div> */}
                    {/* ------------------------------------------- */}
                    {/* </div> */}
                    {/* <div style={{ width: '33.3%' }}> */}
                    {/* <Paper
                      style={{
                        width:"calc(100% - 33.33px)",
                        // height: "275px",
                        padding: "15px",
                        background: "rgba(251, 224, 191, 1)",
                        pointerEvents: "none",
                      }}>
                      {" "}
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                        }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                              color: "black",
                              caretColor: "transparent",
                              pointerEvents: "none",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Status"
                          value={singlelistView && singlelistView.status}
                        />{" "}
                      </div>
                      <div style={{ marginBottom: "7px 0" }}>
                        <div style={{ width: "25%" }}>
                          {" "}
                          <CssValueTextField
                            id="standard-multiline-flexible"
                            multiline
                            maxRows={4}
                            variant="standard"
                            style={
                              {
                                width: "270px",
                                color: "black",
                                caretColor: "transparent",
                                pointerEvents: "none",
                              } //disabled={tp}
                            }
                            onKeyDown={(evt) => evt.preventDefault()}
                            label="Contract Type"
                            value={
                              singlelistView &&
                              singlelistView.contract_type_details.contract_type
                            }
                          />
                        </div>

                      </div>
                      <div style={{ margin: "10px 0" }}>
                  
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <MultipleSelectBar
                          width={"270px"}
                          margin={"10px 0"}
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          value={
                            singlelistView &&
                            singlelistView.ports_details &&
                            singlelistView.ports_details.length > 0
                              ? singlelistView.ports_details.map((data) => {
                                  data[
                                    "namecity"
                                  ] = `${data.name} - ${data.city}`;
                                  return data;
                                })
                              : []
                          }
                          array={
                            singlelistView &&
                            singlelistView.ports_details &&
                            singlelistView.ports_details.length > 0
                              ? singlelistView.ports_details.map((data) => {
                                  data[
                                    "namecity"
                                  ] = `${data.name} - ${data.city}`;
                                  return data;
                                })
                              : [] //array pass krre na toh city nhi aaega
                          }
                          disabled={true}
                          title="city"
                        />
                      </div>
                    </Paper> */}
                  </div>
                </div>
                {POITemTable(
                  null,
                  poItemsArrayTOPreviewCopy,
                  // poItemsArrayTOPreview,
                  null,
                  null,
                  "950px",
                  null,
                  null,
                  "fromPO"
                )}
                <div style={styles.innerDiv1}>
                  <Paper
                    style={{
                      width: "100%",

                      boxShadow: "1px 2px 6px 1px #d3d3d38f",
                      padding: "15px",
                      background: "rgb(0 0 0 / 1%)",
                      pointerEvents: "none",
                    }}>
                    <div style={{ margin: "10px 0" }}>
                      <div style={styles.heading1}>Payment Term</div>
                      <div style={styles.subHeading}>
                        {"Payment shall be made by RTGS within " +
                          `${
                            singlelistView && singlelistView.payment_terms
                              ? PaymentTermList &&
                                PaymentTermList.length > 0 &&
                                PaymentTermList.filter(
                                  (sPT) =>
                                    sPT.id === singlelistView.payment_terms
                                ) &&
                                PaymentTermList.filter(
                                  (sPT) =>
                                    sPT.id === singlelistView.payment_terms
                                )[0] &&
                                PaymentTermList.filter(
                                  (sPT) =>
                                    sPT.id === singlelistView.payment_terms
                                )[0].days_range
                              : ""
                          }` +
                          " WD after receipt of material in our CHA godown /CFS."}
                      </div>
                    </div>

                    <div style={{ margin: "10px 0" }}>
                      <div style={styles.heading1}>Delivery Terms</div>
                      <div style={styles.subHeading}>
                        {`Delivered in buyer’s nominated CHA’s warehouse in` +
                          `${
                            singlelistView &&
                            singlelistView.ports_details &&
                            singlelistView.ports_details.map((list) => {
                              return ` ${list.name} - ${list.city} `;
                            })
                          }` +
                          `by trucks.`}
                      </div>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <div style={styles.heading1}>Remark</div>
                      <div style={styles.subHeading}>
                        {singlelistView && singlelistView.remark}
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
              <div className="marginB10" />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Details;
